import React from 'react'
import CountUp from 'react-countup'
import styled, { css } from 'styled-components'
import { Icon } from '@iconify/react'
import { useTokenRate } from 'hooks/indexingApi'
import { Button, Link, LogoIcon, Text } from '@swapxco/uikit'
import Container from '../../components/Layout/Container'

const StyledXwap = styled.section`

  margin: 100px 0;
  color: white;
  background: url("/images/landingpage/xwap-bg-1.svg") no-repeat top right,
  url("/images/landingpage/xwap-bg-2.svg") no-repeat bottom left,
  linear-gradient(${({ theme }) => theme.colors.primary}, ${({ theme }) => theme.colors.primary});
  background-size: contain, contain, contain;
  position: relative;

  .logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    height: 133px;
    width: 133px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    ${({ theme }) => theme.isDark ? css`
      background: rgba(33, 41, 64, 0.42);
      backdrop-filter: blur(25px);
    ` : css`
      background: rgba(255, 255, 255, 0.31);
      backdrop-filter: blur(25px);
    `}
    svg {
      width: 42px;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      height: 165px;
      width: 165px;

      svg {
        width: 52px;
      }
    }
  }

  .container {
    padding: 95px 24px;
    display: grid;
    gap: 64px;

    .title {
      font-style: normal;
      font-weight: 500;
      color: white;
      text-align: center;
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 20px;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 60px;
        line-height: 80px;
      }

      span {
        font-weight: bold;
      }
    }

    .moto {
      text-align: center;
      color: white;
      max-width: 350px;
      margin: auto;

      font-size: 14px;
      line-height: 21px;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .actions {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      a {
        padding: 16px 27px;
        border-radius: 27px;
        box-shadow: none;
        margin: 5px;

        &.buyswapx {
          background: #FFFFFF;
          color: ${({ theme }) => theme.colors.primary};
        }

        &.tokenomics {
          border: solid 1px #FFFFFF;
        }
      }
    }

    .metrics {
      display: grid;
      gap: 16px;
      justify-content: center;

      ${({ theme }) => theme.mediaQueries.md} {
        gap: 60px;
        grid-auto-flow: column;
        //grid-template-columns: repeat(4, 1fr);
      }

      .metric {
        text-align: center;

        .name {
          color: white;
          font-weight: 500;
          font-size: 14px;
          line-height: 193.1%;
          white-space: nowrap;
        }

        .amount {
          color: white;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 193.1%;
          white-space: nowrap;
        }
      }
    }
  }
`

export default function Xwap() {

  const xwap = useTokenRate({ symbol: "XWAP" });

  return <>
    <StyledXwap>
      <div className='logo'>
        <LogoIcon />
      </div>
      <Container className='container'>
        <div>
          <Text className='title'>
            <span>Xwap</span> Makes Our World <br /> Go Round
          </Text>
          <Text className='moto'>Hold our native governance token to be entitled for rewards and benefits </Text>
          <div className='actions'>
            <Button as={Link} className='buyswapx'
              href='https://pancakeswap.finance/swap?outputCurrency=0x4fe2598b2b29840c42ff1cb1a9cc151b09522a27'>Buy
              Xwap</Button>
            <Button as={Link} className='tokenomics'
              href='https://docs.swapx.co/swapx/tokenomics/xwap-tokenomics'
              target='_blank'>Tokenomics</Button>
            <Button as={Link} className='tokenomics'
              href='https://docs.swapx.co/swapx/tokenomics/xwap-tokenomics'
              target='_blank'>
              <Icon fill='white' height='1.2em' width='1.2em' icon='akar-icons:github-fill' />
              &nbsp;
              Github
            </Button>
            <Button as={Link} className='tokenomics'
              href='https://bscscan.com/token/0x4fe2598b2b29840c42ff1cb1a9cc151b09522a27?a=0x785083457497c7831a7ddfeeb5b576210f64e07e'
              target='_blank'>
              <svg xmlns='http://www.w3.org/2000/svg' style={{ width: '1em' }} viewBox='0 0 121.378 121.333'>
                <g id='bscscan-logo-circle' transform='translate(-219.378 -213.334)'>
                  <path id='Path_1' data-name='Path 1'
                    d='M244.6,271.1a5.144,5.144,0,0,1,5.168-5.143l8.568.028a5.151,5.151,0,0,1,5.151,5.151v32.4c.965-.286,2.2-.591,3.559-.911a4.292,4.292,0,0,0,3.309-4.177V258.261a5.152,5.152,0,0,1,5.151-5.152H284.1a5.152,5.152,0,0,1,5.151,5.152v37.3s2.15-.87,4.243-1.754a4.3,4.3,0,0,0,2.625-3.957V245.383a5.151,5.151,0,0,1,5.15-5.151h8.585A5.151,5.151,0,0,1,315,245.383V282c7.443-5.394,14.986-11.882,20.972-19.683a8.646,8.646,0,0,0,1.316-8.072,60.636,60.636,0,1,0-109.855,50.108,7.668,7.668,0,0,0,7.316,3.79c1.624-.143,3.646-.345,6.05-.627a4.29,4.29,0,0,0,3.805-4.258V271.1'
                    fill='currentColor' />
                  <path id='Path_2' data-name='Path 2'
                    d='M244.417,323.061A60.656,60.656,0,0,0,340.756,274c0-1.4-.065-2.778-.158-4.152-22.163,33.055-63.085,48.508-96.181,53.213'
                    fill='currentColor' />
                </g>
              </svg>
              &nbsp;
              BSC Scan
            </Button>
          </div>
          <div className='metrics'>
            <div className='metric'>
              <Text className='name'>Price per coin</Text>
              <Text className='amount'>
                <CountUp
                  key="price"
                  end={xwap?.result?.price || 0}
                  decimals={3}
                  separator=','
                  preserveValue
                  prefix='~$'
                />
              </Text>
            </div>
            {
              !!xwap?.result?.circulating_supply || true &&
              <div className='metric'>
                <Text className='name'>Circulating Supply</Text>
                <Text className='amount'>
                  <CountUp
                    key="circulating_supply"
                    end={xwap?.result?.circulating_supply || 76 * 10 ** 6}
                    separator=','
                    preserveValue
                    suffix=' XWAP'
                    prefix='~'
                  />
                </Text>
              </div>
            }
            {/* <div className='metric'> */}
            {/*   <Text className='name'>Burned To Date</Text> */}
            {/*   <Text className='amount'>22,849,509.92</Text> */}
            {/* </div> */}
            {
              !!xwap?.result?.market_cap &&
              <div className='metric'>
                <Text className='name'>Market Cap</Text>
                <Text className='amount'>
                  <CountUp
                    key="market_cap"
                    end={xwap?.result?.market_cap || 0}
                    decimals={3}
                    separator=','
                    preserveValue
                    prefix='$'
                    suffix='~'
                  />
                </Text>
              </div>
            }
            {/* <div className='metric'> */}
            {/*   <Text className='name'>Current Emissions</Text> */}
            {/*   <Text className='amount'>10/Block</Text> */}
            {/* </div> */}
          </div>
        </div>
      </Container>
    </StyledXwap>
  </>
}


const XwapCoinArt = () => {
  return <div className='xwap-coin-art'>
    <img src='/images/landingpage/xwap-coin-art.png' alt='' />
  </div>
}