import styled from 'styled-components'
import chromajs from 'chroma-js'

export const StyledSidePanel = styled.nav`
  width: 217px;
  max-width: 100vw;
  height: 100vh;
  background: linear-gradient(${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()}b0, ${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()}b0),
  url("/images/dashboard-sidepanel-bg.svg") no-repeat bottom right,
  linear-gradient(${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()}, ${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()});;
  background-size: 100% auto;
  overflow: auto;
  display: flex;
  flex-direction: column;

  padding-bottom: 32px;

  .brand {
    padding: 32px;
    margin-bottom: 36px;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 26px;
    display: flex;
    align-items: center;
    background: linear-gradient(${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()}, ${({ theme }) => chromajs(theme.colors.background).darken(theme.isDark ? 0.1 : -0.1).hex()});
    position: sticky;
    top: 0;
    z-index: 5;

    svg {
      margin-right: 11px;
    }
  }

`
