import React from 'react'
import styled from 'styled-components'


const StyledBuyXwapButton = styled.a`

  &.icon-button {
    ${({ theme }) => theme.mediaQueries.md} {
      display: none !important;
    }
  }

  &.neomorph-button {
    display: none !important;

    ${({ theme }) => theme.mediaQueries.md} {
      display: flex !important;
    }
  }
`


export const BuyXwapButton = (props) => {

  return <>
    <StyledBuyXwapButton {...props}
                         href='https://pancakeswap.finance/swap?outputCurrency=0x4fe2598b2b29840c42ff1cb1a9cc151b09522a27'
                         target='_blank' rel='noreferrer' className='icon-button'>
      <svg viewBox='0 0 58 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_i_7_3494)'>
          <path fillRule='evenodd' clipRule='evenodd'
                d='M13.2132 22.8891L31.8804 36.9462C32.7305 37.5827 33.379 38.4541 33.7474 39.4548C34.1157 40.4553 34.188 41.5421 33.9554 42.5833C33.7561 43.4805 33.3372 44.3133 32.737 45.0059C32.1369 45.6983 31.3744 46.2288 30.5189 46.5487C29.6634 46.8687 28.7421 46.968 27.8388 46.8376C26.9354 46.7073 26.0789 46.3514 25.3468 45.8026L0.714355 27.2667L1.4908 23.7696C2.71413 18.2797 5.44154 13.2457 9.36298 9.24003C13.2844 5.23427 18.2426 2.41733 23.6738 1.10944C29.1051 -0.198455 34.7916 0.055159 40.0869 1.84145C45.3823 3.62774 50.0741 6.87505 53.6289 11.2142C56.9106 15.2457 53.2555 21.1853 48.2047 20.046C46.9984 19.7832 45.9127 19.1246 45.1177 18.1734C43.0466 15.6673 40.3713 13.7391 37.3482 12.5739C34.3252 11.4086 31.0557 11.0452 27.8533 11.5186C24.6508 11.9919 21.6228 13.2861 19.0592 15.2771C16.4957 17.268 14.4827 19.8892 13.2132 22.8891ZM24.299 24.2735C23.9308 23.2746 23.8575 22.1897 24.0881 21.1498C24.2875 20.2523 24.7064 19.4192 25.3067 18.7264C25.907 18.0336 26.6697 17.503 27.5253 17.1827C28.381 16.8626 29.3026 16.763 30.2062 16.8932C31.1098 17.0233 31.9669 17.3789 32.6993 17.9278L57.2858 36.172L56.5093 39.6664C55.4645 44.397 53.2917 48.7998 50.1788 52.4942C47.3601 55.8491 43.846 58.5411 39.884 60.3806C35.9219 62.2203 31.6079 63.163 27.2454 63.1425C22.8829 63.1219 18.5779 62.1388 14.633 60.2619C10.6882 58.385 7.19932 55.6602 4.41181 52.279C1.10314 48.2365 4.78516 42.2724 9.87127 43.4036C11.0363 43.6609 12.0833 44.3016 12.8472 45.2244C15.3689 48.303 18.7935 50.4978 22.6299 51.494C22.8463 51.5512 23.0654 51.6084 23.2873 51.6602C27.5939 52.6224 32.0988 52.0383 36.0232 50.0085C39.9476 47.9786 43.0446 44.6309 44.7788 40.5442L26.1631 26.7787C25.3146 26.1424 24.6673 25.2725 24.299 24.2735Z'
                fill='#3D5AF1' />
        </g>
        <defs>
          <filter id='filter0_i_7_3494' x='0.714355' y='0.285706' width='56.5713' height='66.8571'
                  filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                           result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='3' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix type='matrix' values='0 0 0 0 0.0973958 0 0 0 0 0.192692 0 0 0 0 0.6875 0 0 0 1 0' />
            <feBlend mode='normal' in2='shape' result='effect1_innerShadow_7_3494' />
          </filter>
        </defs>
      </svg>
    </StyledBuyXwapButton>
    <StyledBuyXwapButton {...props}
                         href='https://pancakeswap.finance/swap?outputCurrency=0x4fe2598b2b29840c42ff1cb1a9cc151b09522a27'
                         target='_blank' rel='noreferrer' className='neomorph-button'>
      <svg viewBox='0 0 58 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_i_7_3494)'>
          <path fillRule='evenodd' clipRule='evenodd'
                d='M13.2132 22.8891L31.8804 36.9462C32.7305 37.5827 33.379 38.4541 33.7474 39.4548C34.1157 40.4553 34.188 41.5421 33.9554 42.5833C33.7561 43.4805 33.3372 44.3133 32.737 45.0059C32.1369 45.6983 31.3744 46.2288 30.5189 46.5487C29.6634 46.8687 28.7421 46.968 27.8388 46.8376C26.9354 46.7073 26.0789 46.3514 25.3468 45.8026L0.714355 27.2667L1.4908 23.7696C2.71413 18.2797 5.44154 13.2457 9.36298 9.24003C13.2844 5.23427 18.2426 2.41733 23.6738 1.10944C29.1051 -0.198455 34.7916 0.055159 40.0869 1.84145C45.3823 3.62774 50.0741 6.87505 53.6289 11.2142C56.9106 15.2457 53.2555 21.1853 48.2047 20.046C46.9984 19.7832 45.9127 19.1246 45.1177 18.1734C43.0466 15.6673 40.3713 13.7391 37.3482 12.5739C34.3252 11.4086 31.0557 11.0452 27.8533 11.5186C24.6508 11.9919 21.6228 13.2861 19.0592 15.2771C16.4957 17.268 14.4827 19.8892 13.2132 22.8891ZM24.299 24.2735C23.9308 23.2746 23.8575 22.1897 24.0881 21.1498C24.2875 20.2523 24.7064 19.4192 25.3067 18.7264C25.907 18.0336 26.6697 17.503 27.5253 17.1827C28.381 16.8626 29.3026 16.763 30.2062 16.8932C31.1098 17.0233 31.9669 17.3789 32.6993 17.9278L57.2858 36.172L56.5093 39.6664C55.4645 44.397 53.2917 48.7998 50.1788 52.4942C47.3601 55.8491 43.846 58.5411 39.884 60.3806C35.9219 62.2203 31.6079 63.163 27.2454 63.1425C22.8829 63.1219 18.5779 62.1388 14.633 60.2619C10.6882 58.385 7.19932 55.6602 4.41181 52.279C1.10314 48.2365 4.78516 42.2724 9.87127 43.4036C11.0363 43.6609 12.0833 44.3016 12.8472 45.2244C15.3689 48.303 18.7935 50.4978 22.6299 51.494C22.8463 51.5512 23.0654 51.6084 23.2873 51.6602C27.5939 52.6224 32.0988 52.0383 36.0232 50.0085C39.9476 47.9786 43.0446 44.6309 44.7788 40.5442L26.1631 26.7787C25.3146 26.1424 24.6673 25.2725 24.299 24.2735Z'
                fill='#3D5AF1' />
        </g>
        <defs>
          <filter id='filter0_i_7_3494' x='0.714355' y='0.285706' width='56.5713' height='66.8571'
                  filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                           result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='3' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix type='matrix' values='0 0 0 0 0.0973958 0 0 0 0 0.192692 0 0 0 0 0.6875 0 0 0 1 0' />
            <feBlend mode='normal' in2='shape' result='effect1_innerShadow_7_3494' />
          </filter>
        </defs>
      </svg>
        Buy Xwap
    </StyledBuyXwapButton>
  </>
}