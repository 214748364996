import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@swapxco/uikit'
import { NeomorphLoader } from 'components/NeomorphLoader'

import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      {/* <NeomorphLoader /> */}
      <video style={{
          height: '50vh',
          width: '50vw', position: "fixed",
          objectFit: "contain",
          objectPosition: "center",
          top: '50%',
          left: '50%',
          transform: "translate(-50%, -50%)",
          zIndex: -1,
          filter: '',
        }} autoPlay muted loop>
          <source src='/loading_mascot.webm' />
        </video>
    </Wrapper>
  )
}

export default PageLoader
