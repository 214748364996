import * as React from 'react'

export const SwitchButton: React.FC<React.SVGAttributes<any>> = (props) => {
  return (
    <>
      <svg viewBox="0 0 74 74" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <circle cx="37" cy="31" r="21" fill="url(#paint0_linear)" />
        </g>
        <path
          d="M31.9999 35.5859L31.9999 24.0002C31.9999 23.4479 31.5522 23.0002 31 23.0002C30.4477 23.0002 30 23.4479 30 24.0002L30 35.5859L27.7071 33.293C27.3166 32.9025 26.6834 32.9025 26.2929 33.293C25.9024 33.6835 25.9024 34.3167 26.2929 34.7072L30.2929 38.7072C30.3161 38.7305 30.3407 38.7526 30.3662 38.7735C30.3777 38.7829 30.3899 38.7912 30.4017 38.8001C30.416 38.8107 30.4299 38.8218 30.4448 38.8317C30.459 38.8412 30.4737 38.8495 30.4883 38.8582C30.5017 38.8662 30.5149 38.8746 30.5287 38.882C30.5435 38.89 30.5589 38.8968 30.5741 38.9039C30.5884 38.9107 30.6026 38.9179 30.6174 38.924C30.6321 38.9301 30.6472 38.9351 30.6622 38.9404C30.678 38.9461 30.6936 38.9521 30.7097 38.957C30.7247 38.9615 30.7399 38.9649 30.7551 38.9687C30.7716 38.9728 30.7879 38.9774 30.8046 38.9808C30.8222 38.9842 30.8399 38.9863 30.8576 38.9888C30.8722 38.9909 30.8866 38.9937 30.9013 38.9951C30.967 39.0016 31.0331 39.0016 31.0987 38.9951C31.1135 38.9937 31.1278 38.9909 31.1425 38.9888C31.1601 38.9863 31.1779 38.9842 31.1954 38.9807C31.2122 38.9774 31.2284 38.9728 31.2449 38.9686C31.2601 38.9648 31.2753 38.9615 31.2903 38.9569C31.3064 38.9521 31.322 38.946 31.3378 38.9404C31.3528 38.935 31.3679 38.93 31.3826 38.9239C31.3974 38.9179 31.4115 38.9106 31.4259 38.9038C31.4411 38.8967 31.4564 38.8899 31.4713 38.8819C31.4851 38.8745 31.4982 38.8661 31.5116 38.8581C31.5262 38.8494 31.541 38.8412 31.5552 38.8316C31.5701 38.8218 31.5839 38.8107 31.5982 38.8001C31.6101 38.7912 31.6223 38.7829 31.6338 38.7734C31.6592 38.7527 31.6835 38.7308 31.7066 38.7077C31.7068 38.7075 31.707 38.7074 31.7071 38.7072L35.7071 34.7072C36.0976 34.3167 36.0976 33.6835 35.7071 33.293C35.3166 32.9025 34.6834 32.9025 34.2929 33.293L31.9999 35.5859Z"
          fill="white"
        />
        <path
          d="M43.6337 23.2268C43.622 23.2172 43.6098 23.2089 43.5979 23.2C43.5837 23.1894 43.5698 23.1784 43.5551 23.1685C43.5408 23.159 43.526 23.1507 43.5114 23.142C43.498 23.134 43.4849 23.1256 43.4712 23.1183C43.4563 23.1103 43.4409 23.1035 43.4257 23.0964C43.4113 23.0896 43.3972 23.0824 43.3825 23.0763C43.3677 23.0702 43.3526 23.0652 43.3376 23.0599C43.3219 23.0542 43.3063 23.0482 43.2902 23.0433C43.2752 23.0387 43.2599 23.0354 43.2447 23.0316C43.2283 23.0274 43.212 23.0229 43.1953 23.0195C43.1777 23.0161 43.16 23.014 43.1423 23.0114C43.1277 23.0093 43.1134 23.0065 43.0986 23.0051C43.033 22.9986 42.9668 22.9986 42.9012 23.0051C42.8864 23.0065 42.8721 23.0093 42.8575 23.0114C42.8398 23.0139 42.822 23.016 42.8045 23.0195C42.7877 23.0229 42.7715 23.0274 42.755 23.0316C42.7399 23.0354 42.7246 23.0387 42.7096 23.0433C42.6935 23.0482 42.6779 23.0542 42.6621 23.0599C42.6471 23.0653 42.632 23.0702 42.6173 23.0763C42.6026 23.0824 42.5884 23.0896 42.5741 23.0964C42.5589 23.1035 42.5435 23.1103 42.5286 23.1183C42.5149 23.1256 42.5018 23.134 42.4884 23.142C42.4738 23.1507 42.459 23.159 42.4447 23.1685C42.43 23.1784 42.4161 23.1894 42.4019 23.2C42.39 23.2089 42.3777 23.2172 42.3661 23.2268C42.341 23.2473 42.3169 23.2691 42.2939 23.292C42.2936 23.2923 42.2932 23.2927 42.2929 23.293L38.2929 27.293C37.9024 27.6835 37.9024 28.3167 38.2929 28.7072C38.6834 29.0977 39.3166 29.0977 39.7071 28.7072L42 26.4143L42 38C42 38.5523 42.4477 39 43 39C43.5522 39 43.9999 38.5523 43.9999 38L43.9999 26.4143L46.2928 28.7072C46.6833 29.0977 47.3165 29.0977 47.707 28.7072C48.0976 28.3167 48.0976 27.6835 47.707 27.293L43.7071 23.293C43.7067 23.2926 43.7063 23.2923 43.706 23.292C43.6829 23.2691 43.6587 23.2473 43.6337 23.2268Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0"
            width="74"
            height="74"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="8" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.239216 0 0 0 0 0.352941 0 0 0 0 0.945098 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient id="paint0_linear" x1="37" y1="10" x2="37" y2="52" gradientUnits="userSpaceOnUse">
            <stop stopColor="#5B76FF" />
            <stop offset="1" stopColor="#3D5AF1" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}
