import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { cssValues } from './consts'


export interface NavLinkProps {
  path?: string
  href?: string
  target?: string
  label: React.ReactNode
  icon?: JSX.Element
  linkActiveMatch?: RegExp
  sublinks?: (Omit<NavLinkProps, 'sublinks' | 'icon'>)[]
}

const StyledNavLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px 0 30px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text};
  margin: 17.5px 0;
  cursor: pointer;
  transition: font-weight 0.4s ${cssValues.easing};
  font-weight: 400;

  .label {
    flex: 1;
  }

  .icon {
    font-size: 24px;
    margin-right: 10px;
    width: 1em;
    height: 1em;
    transition: color 0.4s ${cssValues.easing};
    color: inherit;

    svg {
      width: 1em;
      height: 1em;
    }
  }

  // active indicator bar
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 7px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: transform 0.4s ${cssValues.easing};
    transform-origin: left center;
    transform: scaleX(0);
  }

  &:hover {
    &::before {
      transform: scaleX(0.75);
    }
  }

  &.active {
    font-weight: 600;

    &::before {
      transform: scale(1);
    }

    .icon {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .arrow {
    display: none;
    margin: auto;
    color: inherit;
  }

  &.has-sub-navlinks {
    transition: margin-bottom 0.4s ${cssValues.easing};;

    .arrow {
      display: block;
      transform-origin: center center;
      transition: transform 0.4s ${cssValues.easing};
      transform: rotate(-180deg);
    }

    &.has-sub-navlinks-opened {
      margin-bottom: 2px;

      .arrow {
        transform: rotate(0deg);
      }
    }

    &:not(.has-sub-navlinks-opened) {

      & + .sub-navlinks:not(:focus-within) {
        max-height: 0 !important;
      }
    }
  }

  & + .sub-navlinks {
    max-height: 0;
    transition: max-height 0.4s ${cssValues.easing};;
    overflow: hidden;
    flex: 1 0 100%;

    .navlink {
      margin: 10px 0;
    }
  }
`

export function NavLink(
  {
    path,
    href,
    target,
    label,
    icon,
    linkActiveMatch,
    sublinks,
    ...props
  }: NavLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) {

  const location = useLocation()
  const isActive = useMemo(() => linkActiveMatch
    ? linkActiveMatch.test(location.pathname)
    : location.pathname === path, [linkActiveMatch, location.pathname, path])


  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(false)
  }, [location])

  const maxHeight = useMemo(() => (sublinks?.length || 0) * 38, [sublinks?.length])

  const linkProps = useMemo(() => ({
    as: path ? Link : 'a',
    href,
    to: path,
    target,
  }), [href, path, target])

  return <>
    <StyledNavLink
      onClick={() => setOpen(!open)}
      {...linkProps}
      className={`navlink ${isActive && 'active'} ${sublinks?.length && 'has-sub-navlinks'} ${(open || isActive) && 'has-sub-navlinks-opened'}`}>
      <div className='icon'>{icon}</div>
      <div className='label'>{label}</div>
      <div className='arrow'>
        <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 6.06262L6 1.93738L11 6.06262' stroke='currentColor' strokeWidth='2' strokeLinecap='round'
                strokeLinejoin='round' />
        </svg>
      </div>
    </StyledNavLink>
    {
      !!sublinks?.length &&
      <div
        style={{
          maxHeight,
        }}
        className='sub-navlinks'>
        {sublinks.map(p => <NavLink {...p} />)}
      </div>
    }
  </>
}
