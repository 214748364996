import Container from 'components/Layout/Container'
import React from 'react'
import { StyledNavbar } from './StyledNavbar'


export default function Navbar({ onPanelButtonClick, children, ...props }) {
  return <>
    <StyledNavbar {...props}>
      <button type='button' className='icon-button' onClick={() => onPanelButtonClick?.()}>
        <svg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1L20 0.999998' stroke='#0064FF' strokeWidth='2' strokeLinecap='round' />
          <path d='M1 8L10.5 8M20 8L15.25 8' stroke='#0064FF' strokeWidth='2' strokeLinecap='round' />
          <path d='M1 15L20 15' stroke='#0064FF' strokeWidth='2' strokeLinecap='round' />
        </svg>
      </button>
      {children}
    </StyledNavbar>
  </>
}