import React from 'react'
import styled from 'styled-components'
import { Heading, Text } from '@swapxco/uikit'
import { Button } from 'components/Button'
import Page from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

const StyledComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 112px - 64px);
  justify-content: center;

  video {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    z-index: -1;
    opacity: 0.3;
  }
`

const LinkButton = styled(Button)`
  padding: 30px;
`

const SocialLinks = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  padding: 20px 0;

  .link {
    height: 80px;
    width: 80px;
    background: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 35px;
  }
`

const ComingSoon = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <StyledComingSoon>
        <video muted autoPlay loop src='token-minting.webm' />
        <img src='/images/404.svg' style={{ maxHeight: '40vh' }} alt='coming soon' />
        {/* <LogoIcon width="64px" mb="8px" /> */}
        <Heading color='primary' scale='xxl'>
          COMING SOON
        </Heading>
        <Text color='contrast' m='16px' bold>
          {t('STAY UP TO DATE WITH OUR PRODUCT LAUNCHES')}
        </Text>
        <SocialLinks>
          <a className='link' rel='noreferrer' href='https://t.me/swapx' target='_blank'>
            <Icon height='1.2em' width='1.2em' icon='logos:telegram' />
          </a>
          <a className='link' rel='noreferrer' href='https://twitter.com/SwapXco' target='_blank'>
            <Icon height='1.2em' width='1.2em' icon='logos:twitter' />
          </a>
          <a className='link' rel='noreferrer' href='https://www.instagram.com/swapxco/' target='_blank'>
            <Icon height='1.2em' width='1.2em' color='#cd486b' icon='akar-icons:instagram-fill' />
          </a>
          <a className='link' rel='noreferrer' href='https://discord.com/invite/Mt3CqJXF6w' target='_blank'>
            <Icon height='1.2em' width='1.2em' icon='logos:discord-icon' />
          </a>
          <a className='link' rel='noreferrer' href='https://github.com/swapxteam' target='_blank'>
            <Icon height='1.2em' width='1.2em' icon='akar-icons:github-fill' /></a>
        </SocialLinks>
        <Link to='/'>
          <LinkButton style={{ padding: '16px 70px' }}>{t('Back Home')}</LinkButton>
        </Link>
      </StyledComingSoon>
    </Page>
  )
}

export default ComingSoon
