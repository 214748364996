import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector, WalletConnectConnectorArguments } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from '@swapxco/uikit'
import { ethers } from 'ethers'
import { CHAIN_ID_KEY } from 'config/constants'
import { getNodeUrlByChain } from './getRpcUrl'

const POLLING_INTERVAL = 12000

/**
 * @MOUD Gets the chainId from the localStorage, or worst case scenario fetch it from the env variables 
 * @returns Integer version of the ChainId
 */
export const getChainId = (): number => {
  const chainIdString = localStorage.getItem(CHAIN_ID_KEY) ?? process.env.REACT_APP_CHAIN_ID;
  return parseInt(chainIdString, 10);
}

/**
 * @MOUD this weird looking object is a collection of getter functions to generate and return the connectors
 * By their name, and using the currently selected chainId, this is the best solution that I can come up with 
 * that doesn't change the code.
 */
export const connectorsByName: any = {
  get [ConnectorNames.Injected]() { return new InjectedConnector({ supportedChainIds: [getChainId()] })},
  get [ConnectorNames.WalletConnect]() {
    return new WalletConnectConnector({
      rpc: { [getChainId()]: getNodeUrlByChain(getChainId()) },
      bridge: 'https://pancakeswap.bridge.walletconnect.org/',
      qrcode: true,
      pollingInterval: POLLING_INTERVAL,
    } as WalletConnectConnectorArguments)
  },
  get [ConnectorNames.BSC]() { return new BscConnector({ supportedChainIds: [getChainId()] })}
}

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message)
    return signature
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}
