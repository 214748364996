import React from 'react'
import { Button, TuneIcon, NotificationDot, useModal } from '@swapxco/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import { IconButton } from 'components/IconButton'
import SettingsModal from './SettingsModal'

export default function SettingsTab() {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const [expertMode] = useExpertModeManager()

  return (
    <NotificationDot show={expertMode}>
      <IconButton p={0} onClick={onPresentSettingsModal} id="open-settings-dialog-button">
        <TuneIcon color="primary" width="24px" />
      </IconButton>
    </NotificationDot>
  )
}
