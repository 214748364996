import React, { useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Rellax from 'rellax'
import PlatformCompare from './PlatformCompare'
import NFT from './NFT'
import Partners from './Partners'
import Roadmap from './Roadmap'
import Articles from './Articles'
import PresaleServices from './PresaleServices'
import Xwap from './Xwap'
import PageTitle from '../../components/PageTitle'
import Header from './Header'
import Video from './Video'
import ListedPlatforms from './ListedPlatforms'
import JoinCommunity from './JoinCommunity'
import Footer from './Footer'


const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: ${({ theme }) => theme.isDark ? '#222537' : '#F0F0F3'};
  }

  .text-primary {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const MainContainer = styled.div``


export default function Landingpage() {

  useEffect(() => {
    const rellax = new Rellax('.rellax')
    return () => rellax?.destroy?.()
  }, [])

  return <>
    <GlobalStyles />
    <PageTitle>SwapX | All-in-one Launchpad</PageTitle>
    <MainContainer>
      <Header />
      {/* <Navbar /> */}
      {/* <Hero /> */}
      <div style={{
        background: 'url("/images/landingpage/video-bg.svg") no-repeat bottom center',
        backgroundSize: 'cover',
      }}>
        <Articles />
        <Video />
      </div>
      {/* <UpcomingProjects /> */}
      <div style={{
        background: 'url("/images/landingpage/presale-services-bg.svg") no-repeat center left, radial-gradient(#43A0FF40 , transparent 60%) no-repeat -50vw center',
        backgroundSize: 'contain',
      }}>
        <PresaleServices />
        {/* <Metrics /> */}
      </div>
      <Xwap />
      {/* <Presales /> */}
      <PlatformCompare />
      <ListedPlatforms />
      <NFT />
      <Roadmap />
      <Partners />
      <JoinCommunity />
      <Footer />
    </MainContainer>
  </>
}