import { createGlobalStyle, css } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@swapxco/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Poppins', sans-serif;
  }
  
  html {
    scroll-behavior: smooth;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    /* line-height: 1.2em; */
    letter-spacing: 0.5px;
    img {
      height: auto;
      max-width: 100%;
    }
  }


  .init-loading {
    display: none;
  }
  
  a:hover {
    text-decoration: none !important;
  }

  .tippy-box {
    background-color:  ${({ theme }) => theme.colors.primary} !important;
    color: white !important;
    font-size: 1em;
    &[data-placement^="right"] .tippy-arrow::before {
      border-right-color: ${({ theme }) => theme.colors.primary};
    }

    &[data-placement^="left"] .tippy-arrow::before {
      border-left-color: ${({ theme }) => theme.colors.primary};
    }

    &[data-placement^="bottom"] .tippy-arrow::before {
      border-bottom-color: ${({ theme }) => theme.colors.primary};
    }

    &[data-placement^="top"] .tippy-arrow::before {
      border-top-color: ${({ theme }) => theme.colors.primary};
    }

}
`

export default GlobalStyle
