import React, { useEffect } from 'react'

/**
 * @constructor
 * @description this component sets the Document Title similar to `<title>Page title</title>`
 */
export default function PageTitle({children}: {children: string}) {
  useEffect(() => {
    const originalTitle = document.head.querySelector('title')?.innerText;
    if (!originalTitle) {
      const newTitleElement = document.createElement('title');
      document.head.append(newTitleElement)
    }
    document.head.querySelector('title').innerText = children
    return () => {
      document.head.querySelector('title').innerText = originalTitle
    }
  }, [children])
  return <></>
}