import React from 'react'
import { LogoIcon } from '@swapxco/uikit'
import { StyledSidePanel } from './StyledSidePanel'


export default function SidePanel({onClose = () => null, children, ...props }) {
  return <>
    <StyledSidePanel {...props}>
      <div className='brand'>
        <LogoIcon height='46px' color='currentColor' />
        SwapX
      </div>
      {children}
    </StyledSidePanel>
  </>
}