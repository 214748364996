import React from 'react'
import styled, { css } from 'styled-components'
import { Text } from '@swapxco/uikit'
import Container from '../../components/Layout/Container'
import { newDarkBGLight, newLightBGLight } from './consts'

const StyledServices = styled.section`

  .container {

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
      flex-direction: row;
    }

    .header {
      flex: 0.4;

      margin-bottom: 70px;

      ${({ theme }) => theme.mediaQueries.md} {
        margin-bottom: initial;
      }

      .title {
        font-size: 30px;
        line-height: 38px;

        span {
          color: ${({ theme }) => theme.colors.primary};
          font-weight: bold;
        }

        margin-bottom: 15px;


        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 50px;
          line-height: 69px;
          margin-bottom: 30px;
        }
      }
    }

    .services {
      //padding: 72px 0;
      //max-width: 500px;
      flex: 0.55;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      margin: -20px 0;
      padding-bottom: 20px;

      .card {
        margin: 20px;
        position: relative;
        border: solid 1.4px transparent;
        padding: 25px 20px;
        display: flex;

        ${({ theme }) => theme.mediaQueries.md} {
          flex: 0.5 0 calc(50% - 40px);
          max-width: calc(50% - 40px);
        }

        border-radius: 10px;

        ${({ theme }) => theme.isDark
                ? css`
                  background: #212940;
                  box-shadow: -10px -10px 30px #2F3C5E, 10px 10px 30px #0C0F1E;
                ` : css`
                  background: #F0F0F3;
                  box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
                `
        };

        ${({ theme }) => theme.mediaQueries.md} {
          padding: 25px 20px;
        }

        &.stacking {
          background-color: ${({ theme }) => theme.colors.primary};
          color: white !important;
        }

        .icon {
          margin-right: 20px;
        }


        .name {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 8px;
        }

        .description {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

`

export default function PresaleServices() {
  return <>
    <StyledServices>
      <Container className='container'>
        <div className='header'>
          <Text className='title' as='h3'>Launch Your <br /> Token Through <br /><span>Presale</span></Text>
          <Text style={{ margin: 'auto 0' }}>We build state of the art open source apps
            to access the SwapX protocol and contribute
            to the world of decentralized finance</Text>
        </div>
        <div className='services'>
          <div className='card'>
            <div className='icon'>
              <svg width='45' height='55' viewBox='0 0 45 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='M7 0C3.13401 0 0 3.13401 0 7V48C0 51.866 3.13401 55 7 55H38C41.866 55 45 51.866 45 48V7C45 3.13401 41.866 0 38 0H7ZM29.0256 19.6824C31.3552 19.6824 33.2437 17.7939 33.2437 15.4644C33.2437 13.1348 31.3552 11.2463 29.0256 11.2463C26.6961 11.2463 24.8076 13.1348 24.8076 15.4644C24.8076 17.7939 26.6961 19.6824 29.0256 19.6824ZM20.5756 21.1244C20.5756 19.8546 20.0712 18.6368 19.1733 17.7389C18.2754 16.841 17.0576 16.3365 15.7878 16.3365C14.518 16.3365 13.3002 16.841 12.4023 17.7389C11.5044 18.6368 11 19.8546 11 21.1244H15.7878H20.5756ZM8 23.1143C8 22.0153 8.89091 21.1244 9.9899 21.1244H35.0101C36.1091 21.1244 37 22.0153 37 23.1143C37 23.208 36.9935 23.3002 36.981 23.3905H37L35.4128 32.5094C35.1627 33.9462 33.9156 34.995 32.4573 34.995H22.7868H22.2444H12.574C11.1156 34.995 9.8685 33.9462 9.61843 32.5094L8.08173 23.6806C8.02855 23.5011 8 23.311 8 23.1143ZM18.7559 38.7081C18.7559 40.3165 17.4521 41.6203 15.8438 41.6203C14.2354 41.6203 12.9316 40.3165 12.9316 38.7081C12.9316 37.0998 14.2354 35.796 15.8438 35.796C17.4521 35.796 18.7559 37.0998 18.7559 38.7081ZM14.8757 38.7081C14.8757 39.2428 15.3091 39.6762 15.8438 39.6762C16.3784 39.6762 16.8118 39.2428 16.8118 38.7081C16.8118 38.1735 16.3784 37.7401 15.8438 37.7401C15.3091 37.7401 14.8757 38.1735 14.8757 38.7081ZM29.1563 41.6203C30.7646 41.6203 32.0684 40.3165 32.0684 38.7081C32.0684 37.0998 30.7646 35.796 29.1563 35.796C27.5479 35.796 26.2441 37.0998 26.2441 38.7081C26.2441 40.3165 27.5479 41.6203 29.1563 41.6203ZM29.1563 39.6762C28.6216 39.6762 28.1882 39.2428 28.1882 38.7081C28.1882 38.1735 28.6216 37.7401 29.1563 37.7401C29.6909 37.7401 30.1243 38.1735 30.1243 38.7081C30.1243 39.2428 29.6909 39.6762 29.1563 39.6762Z'
                      fill='#3D5AF1' />
              </svg>
            </div>
            <div className='content'>
              <Text className='name'>Mint</Text>
              <Text className='description'>
                Create a token with adaptive tokenomics
              </Text>
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='45' height='55' viewBox='0 0 45 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='M7 0C3.13401 0 0 3.13401 0 7V48C0 51.866 3.13401 55 7 55H38C41.866 55 45 51.866 45 48V7C45 3.13401 41.866 0 38 0H7ZM32.499 21.5H27.999V18C27.999 16.5413 27.4196 15.1424 26.3881 14.1109C25.3567 13.0795 23.9577 12.5 22.499 12.5C21.0403 12.5 19.6414 13.0795 18.6099 14.1109C17.5785 15.1424 16.999 16.5413 16.999 18V21.5H12.499C11.9688 21.5006 11.4604 21.7115 11.0855 22.0865C10.7105 22.4614 10.4996 22.9698 10.499 23.5V37.5C10.4996 38.0302 10.7105 38.5386 11.0855 38.9135C11.4604 39.2885 11.9688 39.4994 12.499 39.5H32.499C33.0293 39.4994 33.5376 39.2885 33.9126 38.9135C34.2875 38.5386 34.4984 38.0302 34.499 37.5V23.5C34.4984 22.9698 34.2875 22.4614 33.9126 22.0865C33.5376 21.7115 33.0293 21.5006 32.499 21.5ZM23.499 32.3537V34.5C23.499 34.7652 23.3937 35.0196 23.2061 35.2071C23.0186 35.3946 22.7642 35.5 22.499 35.5C22.2338 35.5 21.9795 35.3946 21.7919 35.2071C21.6044 35.0196 21.499 34.7652 21.499 34.5V32.3537C20.6934 32.1135 20.0011 31.5913 19.5488 30.8826C19.0964 30.174 18.9143 29.3262 19.0357 28.4943C19.1571 27.6624 19.5738 26.902 20.2098 26.3521C20.8457 25.8022 21.6583 25.4996 22.499 25.4996C23.3397 25.4996 24.1524 25.8022 24.7883 26.3521C25.4242 26.902 25.841 27.6624 25.9624 28.4943C26.0837 29.3262 25.9016 30.174 25.4493 30.8826C24.997 31.5913 24.3047 32.1135 23.499 32.3537ZM18.999 21.5V18C18.999 17.0717 19.3678 16.1815 20.0242 15.5251C20.6805 14.8687 21.5708 14.5 22.499 14.5C23.4273 14.5 24.3175 14.8687 24.9739 15.5251C25.6303 16.1815 25.999 17.0717 25.999 18V21.5H18.999ZM22.499 30.5C23.3274 30.5 23.999 29.8284 23.999 29C23.999 28.1716 23.3274 27.5 22.499 27.5C21.6706 27.5 20.999 28.1716 20.999 29C20.999 29.8284 21.6706 30.5 22.499 30.5Z'
                      fill='#3D5AF1' />
              </svg>
            </div>
            <div className='content'>
              <Text className='name'>Lock / Vest</Text>
              <Text className='description'>
                Lock your tokens/LP in long term or vesting vaults
              </Text>
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='45' height='55' viewBox='0 0 45 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='M7 0C3.13401 0 0 3.13401 0 7V48C0 51.866 3.13401 55 7 55H38C41.866 55 45 51.866 45 48V7C45 3.13401 41.866 0 38 0H7ZM22.499 12.5C20.234 12.4991 18.024 13.1977 16.1711 14.5004C14.3181 15.803 12.9126 17.646 12.1467 19.7776C11.3808 21.9092 11.2919 24.2253 11.892 26.4093C12.4922 28.5934 13.7522 30.5388 15.4998 31.9797V41.5C15.4997 41.6705 15.5433 41.8381 15.6263 41.987C15.7093 42.1359 15.829 42.2611 15.974 42.3507C16.119 42.4403 16.2845 42.4914 16.4548 42.499C16.6251 42.5067 16.7946 42.4707 16.947 42.3944L22.4989 39.618L28.0526 42.3945C28.2051 42.4707 28.3745 42.5067 28.5448 42.499C28.715 42.4913 28.8805 42.4403 29.0255 42.3506C29.1705 42.261 29.2902 42.1358 29.3732 41.987C29.4562 41.8381 29.4998 41.6705 29.4998 41.5V31.9785C31.247 30.5374 32.5066 28.5919 33.1064 26.4079C33.7062 24.224 33.617 21.9081 32.851 19.7767C32.085 17.6453 30.6795 15.8025 28.8266 14.5001C26.9737 13.1976 24.7639 12.4991 22.499 12.5ZM22.499 32.5C20.719 32.5 18.9789 31.9722 17.4989 30.9832C16.0188 29.9943 14.8653 28.5887 14.1841 26.9442C13.5029 25.2996 13.3247 23.49 13.672 21.7442C14.0192 19.9984 14.8764 18.3947 16.1351 17.136C17.3937 15.8774 18.9974 15.0202 20.7432 14.6729C22.489 14.3257 24.2986 14.5039 25.9432 15.1851C27.5877 15.8663 28.9933 17.0198 29.9823 18.4999C30.9712 19.9799 31.499 21.72 31.499 23.5C31.4963 25.8861 30.5473 28.1737 28.86 29.861C27.1728 31.5482 24.8851 32.4973 22.499 32.5ZM22.499 30.5C26.365 30.5 29.499 27.366 29.499 23.5C29.499 19.634 26.365 16.5 22.499 16.5C18.633 16.5 15.499 19.634 15.499 23.5C15.499 27.366 18.633 30.5 22.499 30.5Z'
                      fill='#3D5AF1' />
              </svg>
            </div>
            <div className='content'>
              <Text className='name'>Presale</Text>
              <Text className='description'>
                Quickly setup and launch a presale for your project
              </Text>
            </div>
          </div>
          <div className='card'>
            <div className='icon'>
              <svg width='45' height='55' viewBox='0 0 45 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='M7 0C3.13401 0 0 3.13401 0 7V48C0 51.866 3.13401 55 7 55H38C41.866 55 45 51.866 45 48V7C45 3.13401 41.866 0 38 0H7ZM9.99609 22.3638L21.9961 29.3638C22.149 29.453 22.3229 29.5 22.5 29.5C22.6771 29.5 22.851 29.453 23.0039 29.3638L35.0039 22.3638C35.1549 22.2757 35.2801 22.1496 35.3672 21.998C35.4542 21.8465 35.5 21.6748 35.5 21.5C35.5 21.3252 35.4542 21.1535 35.3672 21.002C35.2801 20.8504 35.1549 20.7243 35.0039 20.6362L23.0039 13.6362C22.851 13.547 22.6771 13.5 22.5 13.5C22.3229 13.5 22.149 13.547 21.9961 13.6362L9.99609 20.6362C9.84513 20.7243 9.71989 20.8504 9.63285 21.002C9.5458 21.1535 9.5 21.3252 9.5 21.5C9.5 21.6748 9.5458 21.8465 9.63285 21.998C9.71989 22.1496 9.84513 22.2757 9.99609 22.3638ZM22.5 33.3423L33.9961 26.6362C34.2252 26.5026 34.498 26.4654 34.7545 26.5329C35.011 26.6004 35.2301 26.767 35.3638 26.9961C35.4974 27.2252 35.5346 27.498 35.4671 27.7545C35.3996 28.011 35.233 28.2301 35.0039 28.3638L23.0039 35.3638C22.851 35.453 22.6771 35.5 22.5 35.5C22.3229 35.5 22.1491 35.453 21.9961 35.3638L9.99611 28.3638C9.76702 28.2301 9.60041 28.011 9.53292 27.7545C9.46543 27.498 9.5026 27.2252 9.63624 26.9961C9.76989 26.767 9.98906 26.6004 10.2456 26.5329C10.502 26.4654 10.7748 26.5026 11.0039 26.6362L22.5 33.3423ZM22.5 39.3423L33.9961 32.6362C34.1095 32.5701 34.2349 32.5269 34.365 32.5092C34.4951 32.4914 34.6275 32.4995 34.7545 32.5329C34.8815 32.5663 35.0006 32.6244 35.1052 32.7039C35.2097 32.7834 35.2976 32.8827 35.3638 32.9961C35.43 33.1095 35.4731 33.2349 35.4909 33.365C35.5086 33.4951 35.5005 33.6275 35.4671 33.7545C35.4337 33.8815 35.3756 34.0006 35.2961 34.1052C35.2166 34.2097 35.1174 34.2976 35.0039 34.3638L23.0039 41.3638C22.851 41.453 22.6771 41.5 22.5 41.5C22.3229 41.5 22.1491 41.453 21.9961 41.3638L9.99611 34.3638C9.76702 34.2301 9.60041 34.011 9.53292 33.7545C9.46543 33.498 9.5026 33.2252 9.63624 32.9961C9.76989 32.767 9.98906 32.6004 10.2456 32.5329C10.502 32.4654 10.7748 32.5026 11.0039 32.6362L22.5 39.3423Z'
                      fill='#3D5AF1' />
              </svg>
            </div>
            <div className='content'>
              <Text className='name'>Exchange</Text>
              <Text className='description'>
                Trade tokens and earn by providing Liquidity
              </Text>
            </div>
          </div>
          <div className='card stacking'>
            <div className='icon'>
              <svg width='45' height='55' viewBox='0 0 45 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g filter='url(#filter0_i_117_3552)'>
                  <path fillRule='evenodd' clipRule='evenodd'
                        d='M7 0C3.13401 0 0 3.13401 0 7V48C0 51.866 3.13401 55 7 55H38C41.866 55 45 51.866 45 48V7C45 3.13401 41.866 0 38 0H7ZM22.4997 26.2197C30.0729 26.2197 36.2123 23.8198 36.2123 20.8594C36.2123 17.8989 30.0729 15.499 22.4997 15.499C14.9265 15.499 8.78711 17.8989 8.78711 20.8594C8.78711 23.8198 14.9265 26.2197 22.4997 26.2197ZM22.4996 22.5912C26.0364 22.5912 28.9036 21.6431 28.9036 20.4735C28.9036 19.3041 26.0364 18.356 22.4996 18.356C18.9628 18.356 16.0957 19.3041 16.0957 20.4735C16.0957 21.6431 18.9628 22.5912 22.4996 22.5912ZM22.4997 29.4229C28.6731 29.4229 33.8937 27.8282 35.6127 25.6351C36.0025 26.1324 36.2123 26.6605 36.2123 27.2077C36.2123 30.1682 30.073 32.5681 22.4997 32.5681C14.9264 32.5681 8.78711 30.1682 8.78711 27.2077C8.78711 26.6605 8.99687 26.1324 9.38666 25.6351C11.1057 27.8282 16.3263 29.4229 22.4997 29.4229ZM22.4997 36.3558C28.6731 36.3558 33.8937 34.7611 35.6127 32.568C36.0025 33.0653 36.2123 33.5933 36.2123 34.1406C36.2123 37.101 30.073 39.5009 22.4997 39.5009C14.9264 39.5009 8.78711 37.101 8.78711 34.1406C8.78711 33.5933 8.99687 33.0653 9.38666 32.568C11.1057 34.7611 16.3263 36.3558 22.4997 36.3558Z'
                        fill='white' />
                </g>
                <defs>
                  <filter id='filter0_i_117_3552' x='0' y='0' width='45' height='58' filterUnits='userSpaceOnUse'
                          colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                                   result='hardAlpha' />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='1.5' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix'
                                   values='0 0 0 0 0.150312 0 0 0 0 0.25377 0 0 0 0 0.4625 0 0 0 0.21 0' />
                    <feBlend mode='normal' in2='shape' result='effect1_innerShadow_117_3552' />
                  </filter>
                </defs>
              </svg>
            </div>
            <div className='content'>
              <Text color='inherit' className='name'>Staking</Text>
              <Text color='inherit' className='description'>
                Earn new tokens by locking your tokens!
              </Text>
            </div>
          </div>

        </div>
      </Container>
    </StyledServices>
  </>
}