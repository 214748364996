import { request, gql } from 'graphql-request'
import { useEffect, useMemo, useState } from 'react'
import useRefresh from './useRefresh'


const endpoint = 'https://api.staging.swapx.co/graphql'

type TokenRate = {
    price: number;
    name?: string;
    symbol?: string;
    circulating_supply?: number;
    total_supply?: number;
    max_supply?: number;
    num_market_pairs?: number;
    volume?: number;
    market_cap?: number;
    timestamp?: Date;
}

const cachedTokenRates = new Map<string, TokenRate>()

export const useTokenRate = ({ symbol }: { symbol: string }) => {
    const [loading, setLoading] = useState(false)
    const query = useMemo(() => gql`
    {
      CMC {
        latest(symbol: "${symbol}") {
          price,
          circulating_supply,
          market_cap,
        }
      }
    }
  `, [symbol])

    const [result, setResult] = useState<TokenRate | undefined>(() => cachedTokenRates.get(query) || undefined)

    const { fastRefresh } = useRefresh()

    useEffect(() => {
        if (loading) {
            return
        }
        setLoading(true)
        if (['ROD', 'SPT'].includes(symbol)) {
            setResult({ price: 0.01 })
            setLoading(false)
            // return
        }

        request(endpoint, query).then(response => {
            if (response?.CMC?.latest) {
                setResult(response?.CMC?.latest)
                console.log(response?.CMC?.latest);
                cachedTokenRates.set(query, response?.CMC?.latest)
            }
        }).catch(e => {
            console.error(e)
        }).finally(() => {
            setLoading(false)
        })
        // DO NOT REMOVE NEXT LINE & DO NOT INCLUDE `loading` I THE DEPENDENCIES ARRAY
        // eslint-disable-next-line
    }, [query, fastRefresh, symbol])

    return {
        loading, result,
    }
}
