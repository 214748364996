import styled, { css } from 'styled-components'
import { getNeomorphismBoxShadowValue } from '../../theme'
import { cssValues } from './consts'


export const StyledDashboard = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.text};

  svg {
    color: inherit;
  }

  .sidepanel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    pointer-events: none;
    width: 100vw;

    & > nav {
      transition: transform 0.4s ${cssValues.easing};
      transform: translateX(-100%);
    }

    .backdrop {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transition: filter 0.4s ${cssValues.easing};
      background-color: rgba(0, 0, 0, 0.4);
      filter: opacity(0);
      z-index: 0;
    }
  }

  &.open-panel {
    .sidepanel {
      pointer-events: auto;

      & > nav {
        transform: translateX(0);
      }

      .backdrop {
        filter: opacity(1);
      }

    }
  }

  & > main {
    min-height: 100vh;
    padding-bottom: 150px;
    flex: 1;
  }

  @media screen and (min-width: 1200px) {
    .sidepanel {
      position: relative;
      max-width: 217px;
      transition: max-width 0.4s ${cssValues.easing};
      //overflow: hidden;
      pointer-events: auto;

      .backdrop {
        display: none;
      }

      & > nav {
        position: sticky;
        top: 0;
        transform: translateX(0);
      }

    }

    & > main {
      flex: 1;
    }

    &.collapse-panel {
      .sidepanel {
        max-width: 0;

        & > nav {
          transform: translateX(-100%);
        }
      }

      //grid-template-columns: 0 1fr;
    }
  }

  .neomorph-card {
    border-radius: 20px;
    ${({ theme }) => css`
      box-shadow: ${getNeomorphismBoxShadowValue(theme.colors.background)};
      background: ${theme.colors.background};
      color: ${theme.colors.text};
    `}
  }

  .icon-button {
    font-size: 24px;
    width: 1.4375em;
    height: 1.4375em;
    padding: 0.4375em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    ${({ theme }) => css`
      box-shadow: ${getNeomorphismBoxShadowValue(theme.colors.background)};
      background: ${theme.colors.background};
      color: ${theme.colors.text};
    `}
    svg {
      font-size: inherit;
      color: inherit;
      width: 1em;
      height: 1em;
    }
  }

  .neomorph-button {
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 10px 8px;

    @media screen and (min-width: 1200px) {
      padding: 15px 30px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }


    ${({ theme }) => css`
      box-shadow: ${getNeomorphismBoxShadowValue(theme.colors.background)};
      background: ${theme.colors.background};
      color: ${theme.colors.text};
    `}

    border-radius: 40px;

    svg {
      font-size: 24px;
      width: 1em;

      &:first-child {
        margin-right: 10px;
      }


      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .icon-button, .neomorph-button, .neomorph-card.interactive {
    transition: box-shadow 0.2s ${cssValues.easing}, filter 0.2s ${cssValues.easing};

    &.primary {
      ${({ theme }) => css`
        background: ${theme.colors.primary};
        color: white;
      `}
    }

    &:hover {
      filter: brightness(1.05);
      ${({ theme }) => css`
        box-shadow: ${getNeomorphismBoxShadowValue(theme.colors.background, 4)};
      `}
    }

    &:active {
      ${({ theme }) => css`
        box-shadow: ${getNeomorphismBoxShadowValue(theme.colors.background, 3)};
      `}
    }

    &[disabled] {
      filter: opacity(0.5) !important;
      cursor: not-allowed;
    }
  }

  input.neomorph-input, textarea.neomorph-input {
    padding: 10px 23px;
    border: 0;
    border-radius: 27.5px;
    color: ${({ theme }) => theme.colors.text};

    ::placeholder {
      color: hsla(231, 10%, 63%, 1);
    }

    ${({ theme }) => theme.isDark ? css`
      background: #192035;
      box-shadow: 0px 6px 10px #313C57, inset 0px 4px 10px rgba(11, 17, 34, 0.6);
    ` : css`
      background: #F0F0F3;
      box-shadow: 0px 3px 10px #FFFFFF, inset 0px 4px 5px rgba(174, 174, 192, 0.36);
    `}

    appearance: none;
    
    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
      appearance: auto;
      opacity: 1;
    }
  }

  .input-label {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .input-message {
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
  }

  .social-links {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 9px;
    margin: auto 34px;
  }

  #navbar {
    .social-links {
      display: none;
    }
  }

  .sidepanel {
    .social-links {
      display: grid;
      margin: 17.5px 0;
      padding: 0 10px;
      grid-gap: 5px;
    }
  }

  @media screen and (min-width: 1200px) {
    #navbar {
      .social-links {
        display: grid;
      }
    }

    .sidepanel {
      .social-links {
        display: none;
      }
    }
  }
`
