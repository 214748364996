import React from 'react'
import { HistoryIcon, Button, useModal } from '@swapxco/uikit'
import { IconButton } from 'components/IconButton'
import TransactionsModal from './TransactionsModal'

const Transactions = () => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <IconButton p={0} onClick={onPresentTransactionsModal} ml="16px">
        <HistoryIcon color="primary" width="24px" />
      </IconButton>
    </>
  )
}

export default Transactions
