// const PANCAKE_EXTENDED = 'https://tokens.pancakeswap.finance/pancakeswap-extended.json'
// const PANCAKE_TOP100 = 'https://tokens.pancakeswap.finance/pancakeswap-top-100.json'

const SWAPX_1_1_2 = 'https://gateway.pinata.cloud/ipfs/QmQqvhEatLoBvDQkmnCCdib4jbpDWTWtuaFfNFW3Rfbegm';
const SWAPX_1_2_2 = 'https://gateway.pinata.cloud/ipfs/QmRkYEmego3dpRGTHh6PXkqxa9wMETaPtpCDRGtXTAMoLr';

const SWAPX_EXTENDED = SWAPX_1_2_2;

export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  // PANCAKE_TOP100,
  // PANCAKE_EXTENDED,
  SWAPX_EXTENDED,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [SWAPX_EXTENDED]
