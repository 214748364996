import Color from 'tinycolor2';

export const getNeomorphismBoxShadowValue = (color: string, distance = 7, intensity = 10, inset = false) => {
  const clr = new Color(color);
  return `${inset ? 'inset': ''} ${-distance}px ${-distance}px ${distance * 3}px ${clr.lighten(intensity * 0.5).toRgbString()}, ${inset ? 'inset': ''} ${distance}px ${distance}px ${distance * 3}px ${clr.darken(intensity * 1.2).toRgbString()}`
}


export const getNeomorphismFilterDropShadowValue = (color: string, distance = 7, intensity = 10) => {
  const clr = new Color(color);
  return `drop-shadow(${-distance}px ${-distance}px ${distance * 2}px ${clr.lighten(intensity * 0.5).toRgbString()}) drop-shadow(${distance}px ${distance}px ${distance * 2}px ${clr.darken(intensity * 1.2).toRgbString()})`
}