import { AppBody } from 'components/App'
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import Page from 'views/Page'
import Swap from 'views/Swap'
import { RedirectToSwap } from 'views/Swap/redirects'

const Liquidity = React.lazy(() => import('views/Pool'))

export const Trade = () => {
  return (
    <>
      <Page style={{backgroundImage: "url(/images/swap-bg.png)", backgroundSize: '80%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center 100px'}}>
          <Switch>
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/liquidity" component={Liquidity} />
          </Switch>
      </Page>
    </>
  )
}
