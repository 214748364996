import styled from 'styled-components'
import { ButtonMenu as UIKitButtonMenu, ButtonMenuItem as UIKitButtonMenuItem } from '@swapxco/uikit'
import { getNeomorphismBoxShadowValue } from '../../theme'

export const ButtonMenu = styled(UIKitButtonMenu)`
  box-shadow: ${({ theme }) => getNeomorphismBoxShadowValue(theme.colors.background, 3)};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50px;
  border: 0; 
`

export const ButtonMenuItem = styled(UIKitButtonMenuItem)`
  padding: 20px;
  border-radius: 50px;
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.primary : 'transparent'};
  color: ${({ theme, isActive }) => isActive ? theme.colors.invertedContrast : theme.colors.contrast};
`