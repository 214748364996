import React from 'react'
import styled, { css } from 'styled-components'
import { Button, Link, LogoIcon, Text } from '@swapxco/uikit'
import Container from 'components/Layout/Container'
import { getNeomorphismBoxShadowValue } from 'theme'

const StyledFooter = styled.section`
  background: linear-gradient(${({ theme }) => theme.isDark ? '#181A26a0' : 'transparent'}, ${({ theme }) => theme.isDark ? '#181A26a0' : 'transparent'}),
  url("/images/landingpage/footer-bg.svg") no-repeat bottom left,
  linear-gradient(${({ theme }) => theme.isDark ? '#181A26' : '#EAECF3'}, ${({ theme }) => theme.isDark ? '#181A26' : '#EAECF3'});
  color: ${({ theme }) => theme.colors.text};

  flex: 1 0 100%;

  .brand {
    padding: 0.25em 0;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 40px;

    svg {
      margin-right: 26px;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      justify-content: initial;
    }
  }

  .container {
    position: relative;
    display: grid;
    padding: 80px 24px;
    grid-gap: 42px;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;

    ${({ theme }) => theme.mediaQueries.md} {
      text-align: left;
      padding: 100px;
      grid-template-columns: repeat(4, 1fr);
    }

    & > div:first-child {
      grid-column: 1 / 3;

      ${({ theme }) => theme.mediaQueries.md} {
        grid-column: 1 / 2;
      }
    }
  }

  .social-links {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 9px;
    margin-bottom: 26px;

    a {
      width: 38px;
      height: 38px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      ${({ theme }) => theme.isDark ? css`
        background: #212940;
        box-shadow: -10px -10px 30px #2F3C5E, 10px 10px 30px #0C0F1E;
        color: white;
      ` : css`
        background: #F0F0F3;
        box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 20px rgba(174, 174, 192, 0.4);
        color: ${theme.colors.primary};
      `}
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: start;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 18px;
    }

    a {
      //display: block;
      text-align: left;
      font-size: 16px;
      line-height: 31px;
      margin-bottom: 10px;
      margin-right: auto;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        margin-top: -6px;
        background-color: ${({ theme }) => theme.colors.primary};
        flex: 1 0 100%;
        transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
        transform: scaleX(0);
      }

      &:hover {
        text-decoration: none !important;

        &::after {
          transform: scaleX(1) !important;
        }
      }
    }
  }
}

.support {
  grid-column: 1 / 3;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-column: initial;
  }
}

.coin {
  position: absolute;
  right: 24px;
  top: 0;
  height: 85px;
  width: 85px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${({ theme }) => theme.isDark ? css`
    background: #181A26;
    box-shadow: -10px -10px 30px #24273E, 10px 10px 30px #000104;
    color: white;
  ` : css`
    background: #F0F0F3;
    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
    color: ${theme.colors.primary};
  `}
  ${({ theme }) => theme.mediaQueries.md} {
    height: 135px;
    width: 135px;
  }
}
`

export default function Footer() {
  return <>
    <StyledFooter>
      <Container className='container'>
        <div>
          <div className='brand'>
            <LogoIcon height='63px' color='currentColor' />
            SwapX
          </div>
          <div className='social-links'>
            <Button as={Link} href='https://t.me/swapx'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM8.89 13.17L8.903 13.163L9.773 16.033C9.885 16.344 10.039 16.4 10.226 16.374C10.414 16.349 10.513 16.248 10.636 16.13L11.824 14.982L14.374 16.87C14.84 17.127 15.175 16.994 15.291 16.438L16.948 8.616C17.131 7.888 16.811 7.596 16.246 7.828L6.513 11.588C5.849 11.854 5.853 12.226 6.393 12.391L8.89 13.171V13.17Z'
                  fill='currentColor' />
              </svg>
            </Button>
            <Button as={Link} href='https://twitter.com/SwapXco'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M22.1623 5.65593C21.3989 5.99362 20.5893 6.2154 19.7603 6.31393C20.634 5.79136 21.288 4.96894 21.6003 3.99993C20.7803 4.48793 19.8813 4.82993 18.9443 5.01493C18.3149 4.34151 17.4807 3.89489 16.5713 3.74451C15.6618 3.59413 14.7282 3.74842 13.9156 4.18338C13.1029 4.61834 12.4567 5.30961 12.0774 6.14972C11.6981 6.98983 11.607 7.93171 11.8183 8.82893C10.1554 8.74558 8.52863 8.31345 7.04358 7.56059C5.55854 6.80773 4.24842 5.75097 3.1983 4.45893C2.82659 5.09738 2.63125 5.82315 2.6323 6.56193C2.6323 8.01193 3.3703 9.29293 4.4923 10.0429C3.82831 10.022 3.17893 9.84271 2.5983 9.51993V9.57193C2.5985 10.5376 2.93267 11.4735 3.54414 12.221C4.15562 12.9684 5.00678 13.4814 5.9533 13.6729C5.33691 13.84 4.6906 13.8646 4.0633 13.7449C4.33016 14.5762 4.8503 15.3031 5.55089 15.824C6.25147 16.3449 7.09743 16.6337 7.9703 16.6499C7.10278 17.3313 6.10947 17.8349 5.04718 18.1321C3.98488 18.4293 2.87442 18.5142 1.7793 18.3819C3.69099 19.6114 5.91639 20.264 8.1893 20.2619C15.8823 20.2619 20.0893 13.8889 20.0893 8.36193C20.0893 8.18193 20.0843 7.99993 20.0763 7.82193C20.8952 7.23009 21.6019 6.49695 22.1633 5.65693L22.1623 5.65593Z'
                  fill='currentColor' />
              </svg>
            </Button>
            <Button as={Link} href='https://github.com/swapxteam'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12 2C6.475 2 2 6.475 2 12C1.99887 14.0993 2.65882 16.1456 3.88622 17.8487C5.11362 19.5517 6.84615 20.8251 8.838 21.488C9.338 21.575 9.525 21.275 9.525 21.012C9.525 20.775 9.512 19.988 9.512 19.15C7 19.613 6.35 18.538 6.15 17.975C6.037 17.687 5.55 16.8 5.125 16.562C4.775 16.375 4.275 15.912 5.112 15.9C5.9 15.887 6.462 16.625 6.65 16.925C7.55 18.437 8.988 18.012 9.562 17.75C9.65 17.1 9.912 16.663 10.2 16.413C7.975 16.163 5.65 15.3 5.65 11.475C5.65 10.387 6.037 9.488 6.675 8.787C6.575 8.537 6.225 7.512 6.775 6.137C6.775 6.137 7.612 5.875 9.525 7.163C10.3391 6.93706 11.1802 6.82334 12.025 6.825C12.875 6.825 13.725 6.937 14.525 7.162C16.437 5.862 17.275 6.138 17.275 6.138C17.825 7.513 17.475 8.538 17.375 8.788C18.012 9.488 18.4 10.375 18.4 11.475C18.4 15.313 16.063 16.163 13.838 16.413C14.2 16.725 14.513 17.325 14.513 18.263C14.513 19.6 14.5 20.675 14.5 21.013C14.5 21.275 14.688 21.587 15.188 21.487C17.173 20.8168 18.8979 19.541 20.1199 17.8392C21.3419 16.1373 21.9994 14.0951 22 12C22 6.475 17.525 2 12 2Z'
                  fill='currentColor' />
              </svg>
            </Button>
            <Button as={Link} href='https://www.instagram.com/swapxco/'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12 2C14.717 2 15.056 2.01 16.122 2.06C17.187 2.11 17.912 2.277 18.55 2.525C19.21 2.779 19.766 3.123 20.322 3.678C20.8305 4.1779 21.224 4.78259 21.475 5.45C21.722 6.087 21.89 6.813 21.94 7.878C21.987 8.944 22 9.283 22 12C22 14.717 21.99 15.056 21.94 16.122C21.89 17.187 21.722 17.912 21.475 18.55C21.2247 19.2178 20.8311 19.8226 20.322 20.322C19.822 20.8303 19.2173 21.2238 18.55 21.475C17.913 21.722 17.187 21.89 16.122 21.94C15.056 21.987 14.717 22 12 22C9.283 22 8.944 21.99 7.878 21.94C6.813 21.89 6.088 21.722 5.45 21.475C4.78233 21.2245 4.17753 20.8309 3.678 20.322C3.16941 19.8222 2.77593 19.2175 2.525 18.55C2.277 17.913 2.11 17.187 2.06 16.122C2.013 15.056 2 14.717 2 12C2 9.283 2.01 8.944 2.06 7.878C2.11 6.812 2.277 6.088 2.525 5.45C2.77524 4.78218 3.1688 4.17732 3.678 3.678C4.17767 3.16923 4.78243 2.77573 5.45 2.525C6.088 2.277 6.812 2.11 7.878 2.06C8.944 2.013 9.283 2 12 2ZM12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7V7ZM18.5 6.75C18.5 6.41848 18.3683 6.10054 18.1339 5.86612C17.8995 5.6317 17.5815 5.5 17.25 5.5C16.9185 5.5 16.6005 5.6317 16.3661 5.86612C16.1317 6.10054 16 6.41848 16 6.75C16 7.08152 16.1317 7.39946 16.3661 7.63388C16.6005 7.8683 16.9185 8 17.25 8C17.5815 8 17.8995 7.8683 18.1339 7.63388C18.3683 7.39946 18.5 7.08152 18.5 6.75ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9V9Z'
                  fill='currentColor' />
              </svg>
            </Button>
            <Button as={Link} href='https://discord.com/invite/Mt3CqJXF6w'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10.076 11C10.676 11 11.162 11.45 11.151 12C11.151 12.55 10.677 13 10.076 13C9.486 13 9 12.55 9 12C9 11.45 9.475 11 10.076 11ZM13.924 11C14.525 11 15 11.45 15 12C15 12.55 14.525 13 13.924 13C13.334 13 12.849 12.55 12.849 12C12.849 11.45 13.323 11 13.924 11ZM18.891 2C20.054 2 21 2.966 21 4.163V23L18.789 21.005L17.544 19.829L16.227 18.579L16.773 20.522H5.109C3.946 20.522 3 19.556 3 18.359V4.163C3 2.966 3.946 2 5.109 2H18.89H18.891ZM14.921 15.713C17.194 15.64 18.069 14.117 18.069 14.117C18.069 10.736 16.587 7.995 16.587 7.995C15.107 6.862 13.697 6.893 13.697 6.893L13.553 7.061C15.302 7.607 16.114 8.395 16.114 8.395C15.1591 7.85699 14.1066 7.51434 13.018 7.387C12.3274 7.309 11.6299 7.31572 10.941 7.407C10.879 7.407 10.827 7.418 10.766 7.428C10.406 7.46 9.531 7.596 8.431 8.09C8.051 8.268 7.824 8.395 7.824 8.395C7.824 8.395 8.678 7.565 10.529 7.019L10.426 6.893C10.426 6.893 9.017 6.862 7.536 7.996C7.536 7.996 6.055 10.736 6.055 14.117C6.055 14.117 6.919 15.639 9.192 15.713C9.192 15.713 9.572 15.241 9.882 14.842C8.575 14.442 8.082 13.602 8.082 13.602C8.082 13.602 8.184 13.676 8.369 13.781C8.379 13.791 8.389 13.802 8.41 13.812C8.441 13.834 8.472 13.844 8.503 13.865C8.76 14.012 9.017 14.127 9.253 14.222C9.675 14.39 10.179 14.558 10.766 14.674C11.6453 14.8464 12.5494 14.8498 13.43 14.684C13.9429 14.5925 14.4433 14.4411 14.921 14.233C15.281 14.096 15.682 13.896 16.104 13.613C16.104 13.613 15.59 14.474 14.242 14.863C14.551 15.262 14.922 15.713 14.922 15.713H14.921Z'
                  fill='currentColor' />
              </svg>
            </Button>
          </div>
          <Text className='copyrights'>© 2021 - SwapX</Text>
        </div>
        <nav>
          <div className='title'>Token</div>
          <a rel='noreferrer' href='https://docs.swapx.co/swapx/tokenomics/xwap-tokenomics'
             target='_blank'>Tokenomics</a>
          <a rel='noreferrer' href='https://docs.swapx.co/swapx/introduction/master' target='_blank'>Whitepaper</a>
          <a rel='noreferrer'
             href='https://pancakeswap.finance/swap?outputCurrency=0x4fe2598b2b29840c42ff1cb1a9cc151b09522a27'
             target='_blank'>Buy $XWAP</a>
          <a rel='noreferrer' href='https://coinmarketcap.com/currencies/swapx' target='_blank'>Historical Market
            Data</a>
        </nav>
        <nav>
          <div className='title'>About Us</div>
          <a rel='noreferrer' href='https://swapx.co/privacy-policy' target='_blank'>Privacy Policy</a>
          <a rel='noreferrer' href='https://swapx.co/careers' target='_blank'>Careers</a>
          <a rel='noreferrer' href='https://docs.swapx.co/swapx/' target='_blank'>Docs</a>
          <a rel='noreferrer' href='https://t.me/swapxnews' target='_blank'>Announcements</a>
        </nav>
        <nav className='support'>
          <div className='title'>Support</div>
          <a rel='noreferrer' href='/contact' target='_blank'>Contact Us</a>
          <a rel='noreferrer' href='https://docs.swapx.co/swapx/help/faq' target='_blank'>F.A.Q</a>
          <a rel='noreferrer' href='mailto:support@swapx.co' target='_blank'>Give us Feedback</a>
        </nav>
        <div className='coin'>
          <svg width='58' height='64' viewBox='0 0 58 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g filter='url(#filter0_i_7_3494)'>
              <path fillRule='evenodd' clipRule='evenodd'
                    d='M13.2132 22.8891L31.8804 36.9462C32.7305 37.5827 33.379 38.4541 33.7474 39.4548C34.1157 40.4553 34.188 41.5421 33.9554 42.5833C33.7561 43.4805 33.3372 44.3133 32.737 45.0059C32.1369 45.6983 31.3744 46.2288 30.5189 46.5487C29.6634 46.8687 28.7421 46.968 27.8388 46.8376C26.9354 46.7073 26.0789 46.3514 25.3468 45.8026L0.714355 27.2667L1.4908 23.7696C2.71413 18.2797 5.44154 13.2457 9.36298 9.24003C13.2844 5.23427 18.2426 2.41733 23.6738 1.10944C29.1051 -0.198455 34.7916 0.055159 40.0869 1.84145C45.3823 3.62774 50.0741 6.87505 53.6289 11.2142C56.9106 15.2457 53.2555 21.1853 48.2047 20.046C46.9984 19.7832 45.9127 19.1246 45.1177 18.1734C43.0466 15.6673 40.3713 13.7391 37.3482 12.5739C34.3252 11.4086 31.0557 11.0452 27.8533 11.5186C24.6508 11.9919 21.6228 13.2861 19.0592 15.2771C16.4957 17.268 14.4827 19.8892 13.2132 22.8891ZM24.299 24.2735C23.9308 23.2746 23.8575 22.1897 24.0881 21.1498C24.2875 20.2523 24.7064 19.4192 25.3067 18.7264C25.907 18.0336 26.6697 17.503 27.5253 17.1827C28.381 16.8626 29.3026 16.763 30.2062 16.8932C31.1098 17.0233 31.9669 17.3789 32.6993 17.9278L57.2858 36.172L56.5093 39.6664C55.4645 44.397 53.2917 48.7998 50.1788 52.4942C47.3601 55.8491 43.846 58.5411 39.884 60.3806C35.9219 62.2203 31.6079 63.163 27.2454 63.1425C22.8829 63.1219 18.5779 62.1388 14.633 60.2619C10.6882 58.385 7.19932 55.6602 4.41181 52.279C1.10314 48.2365 4.78516 42.2724 9.87127 43.4036C11.0363 43.6609 12.0833 44.3016 12.8472 45.2244C15.3689 48.303 18.7935 50.4978 22.6299 51.494C22.8463 51.5512 23.0654 51.6084 23.2873 51.6602C27.5939 52.6224 32.0988 52.0383 36.0232 50.0085C39.9476 47.9786 43.0446 44.6309 44.7788 40.5442L26.1631 26.7787C25.3146 26.1424 24.6673 25.2725 24.299 24.2735Z'
                    fill='#3D5AF1' />
            </g>
            <defs>
              <filter id='filter0_i_7_3494' x='0.714355' y='0.285706' width='56.5713' height='66.8571'
                      filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                               result='hardAlpha' />
                <feOffset dy='4' />
                <feGaussianBlur stdDeviation='3' />
                <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                <feColorMatrix type='matrix' values='0 0 0 0 0.0973958 0 0 0 0 0.192692 0 0 0 0 0.6875 0 0 0 1 0' />
                <feBlend mode='normal' in2='shape' result='effect1_innerShadow_7_3494' />
              </filter>
            </defs>
          </svg>

        </div>
      </Container>
    </StyledFooter>
  </>
}