import { Button } from "components/Button";
import styled from "styled-components";
import { getNeomorphismBoxShadowValue } from "theme";

export const IconButton = styled(Button)`
  width: 40px;
  height: 40px;
  font-size: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.contrast};
  background-color: ${({ theme, variant = 'background' }) => theme.colors[variant]};
  box-shadow: ${({ theme }) => getNeomorphismBoxShadowValue(theme.colors.background, 3)};
`
