import { createReducer } from "@reduxjs/toolkit";
import { updateChainId } from "./actions";
import { ChainIdState } from "./interfaces";

const initialState: ChainIdState = {
    chainId: '56'
}

export default createReducer(initialState, (builder) => 
    builder
        .addCase(updateChainId, (state, { payload: { chainId }}) => ({ chainId })))