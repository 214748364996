import React, { lazy, useEffect } from 'react'
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom'
import { ResetCSS } from '@swapxco/uikit'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import ComingSoon from 'views/ComingSoon'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import { useFetchProfile } from 'state/profile/hooks'
import Dashboard from 'components/Dashboard'
import GlobalStyle from './style/Global'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
// Views included in the main bundle
import { Trade } from './views/Trade'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly } from './views/Swap/redirects'
import Landingpage from './views/Landingpage'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
// const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Presales = lazy(() => import('./views/Presales'))
const _Presales = lazy(() => import('./views/_Presales'))
// const Farms = lazy(() => import('./views/Farms'))
// const FarmAuction = lazy(() => import('./views/FarmAuction'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Ifos = lazy(() => import('./views/Ifos'))
// const Collectibles = lazy(() => import('./views/Collectibles'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Profile = lazy(() => import('./views/Profile'))
// const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const Voting = lazy(() => import('./views/Voting'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
const Contact = lazy(() => import('./views/Contact'))
const Help = lazy(() => import('./views/Help'))
const Accelerator = lazy(() => import('./views/Accelerator'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const FasterHomeLoading = ({ children }) => {
  const location = useLocation()

  console.log(location.pathname)
  if (location.pathname === '/') {
    return <Landingpage />
  }
  return children
}

const App: React.FC = (props) => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  // usePollCoreFarmData()

  const { account, chainId } = useWeb3React()

  useEffect(() => {
    if (account && !localStorage[`${account}-chain-${chainId}`]) {
      window.gtag('event', 'new_wallet_connect', {
        wallet_address: account,
        chain_id: chainId,
      })
      localStorage[`${account}-chain-${chainId}`] = 'connected'
    }
  }, [account, chainId])

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <FasterHomeLoading>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            
            <Route path='/accelerator' exact component={Accelerator} />

            <Route path='/'>
              <Dashboard>
                <Switch>
                  {/* <Route exact path="/farms/auction">
              <FarmAuction />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/ifo">
              <Ifos />
            </Route>
            <Route path="/collectibles">
              <Collectibles />
            </Route>
            <Route exact path="/teams">
              <Teams />
            </Route>
            <Route path="/teams/:id">
              <Team />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/competition">
              <TradingCompetition />
            </Route>
            <Route path="/prediction">
              <Predictions />
            </Route>
            <Route exact path="/voting">
              <Voting />
            </Route>
            <Route exact path="/voting/proposal/create">
              <CreateProposal />
            </Route>
            <Route path="/voting/proposal/:id">
              <Proposal />
            </Route> */}

                  {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
                  <Route path='/:trade(swap.*|liquidity.*)' component={Trade} />
                  <Route exact strict path='/send' component={RedirectPathToSwapOnly} />
                  <Route exact strict path='/find' component={PoolFinder} />
                  <Route exact strict path='/create' component={RedirectToAddLiquidity} />
                  <Route exact path='/add' component={AddLiquidity} />
                  <Route exact path='/add/:currencyIdA' component={RedirectOldAddLiquidityPathStructure} />
                  <Route exact path='/add/:currencyIdA/:currencyIdB' component={RedirectDuplicateTokenIds} />
                  <Route exact path='/create' component={AddLiquidity} />
                  <Route exact path='/create/:currencyIdA' component={RedirectOldAddLiquidityPathStructure} />
                  <Route exact path='/create/:currencyIdA/:currencyIdB' component={RedirectDuplicateTokenIds} />
                  <Route exact strict path='/remove/:tokens' component={RedirectOldRemoveLiquidityPathStructure} />
                  <Route exact strict path='/remove/:currencyIdA/:currencyIdB' component={RemoveLiquidity} />

                  {/* <Route exact path='/contact' component={Contact} /> */}
                  <Route exact path='/help' component={Help} />

                  {/* Redirect */}
                  <Route path='/pool'>
                    <Redirect to='/liquidity' />
                  </Route>
                  <Route path='/syrup'>
                    <Redirect to='/pools' />
                  </Route>
                  {/* <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route> */}

                  {/* @monabbous SwapX Routes */}
                  <Route path='/presales' component={_Presales} />
                  <Route path='/:comingsoon(token-minting|token-lock|nft|staking)'
                    component={ComingSoon} />

                  {/* 404 */}
                  <Route component={NotFound} />
                </Switch>
              </Dashboard>
            </Route>
          </Switch>
        </SuspenseWithChunkError>
        {/* <EasterEgg iterations={2} /> */}
      </FasterHomeLoading>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
