import { Dropdown } from '@swapxco/uikit'
import React from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { getNeomorphismBoxShadowValue } from 'theme'
import { CHAIN_ID_KEY } from '../../config/constants'
import useAuth from '../../hooks/useAuth'


const DropdownButton = styled.button`
  z-index: 5;
  position: relative;
  white-space: nowrap;
  & + div {
    box-shadow: ${({ theme }) => getNeomorphismBoxShadowValue(theme.colors.background, 5, 20)};
    z-index: 4;
    padding: 8px 0;
  }
`

const DropdownItem = styled.button`
  border: 0;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.text};
  background: inherit;
  cursor: pointer;
  padding: 8px 20px;
  transition: all 0.2s ease-in-out;

  &.active {
    font-weight: bold;

    &::before {
      content: '• ';
    }
  }

  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`

export const ChainSelect = () => {

  const [chainId, setChainId] = React.useState(localStorage.getItem(CHAIN_ID_KEY))
  const { account } = useWeb3React()
  const { logout } = useAuth()
  React.useEffect(() => {
    console.log('Loaded menu.')
    // @MOUD get the locally stored chain ID or set it if it doesn't exist
    const localStorageChainId = localStorage.getItem(CHAIN_ID_KEY)

    if (!localStorageChainId) localStorage.setItem(CHAIN_ID_KEY, process.env.REACT_APP_CHAIN_ID)

    setChainId(localStorageChainId)
  }, [])

  const handleChainIdChange = (option: any): void => {
    const localStorageChainId = localStorage.getItem(CHAIN_ID_KEY)

    console.log('Changed handle chain ID: ', option)
    console.log('local Storage Chain Id: ', localStorageChainId)

    if (account) logout()
    localStorage.setItem(CHAIN_ID_KEY, option.value)
    setChainId(option.value)
  }


  return <>
    <Dropdown target={<DropdownButton type='button' className='neomorph-button'>{({
      56: 'BSC Mainnet',
      97: 'BSC Testnet',
    })[chainId]} ▼</DropdownButton>}>
      <DropdownItem type='button' onClick={() => handleChainIdChange({ value: '56' })}
                    className={`${chainId === '56' && 'active'}`}>BSC Mainnet
      </DropdownItem>
      <DropdownItem type='button' onClick={() => handleChainIdChange({ value: '97' })}
                    className={`${chainId === '97' && 'active'}`}>BSC Testnet
      </DropdownItem>
    </Dropdown>
  </>
}