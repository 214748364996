import { BigNumber } from 'ethers'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useMulticallContract } from './useContract'

// gets the current timestamp from the blockchain
export default function useCurrentBlockTimestamp(): BigNumber | undefined {
  const multicall = useMulticallContract()
  
  const response = useSingleCallResult(multicall, 'getCurrentBlockTimestamp');

  const currentTimestamp = response?.result?.[0];

  return currentTimestamp;
}
