import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { ButtonMenuProps } from '@swapxco/uikit'
import { ButtonMenu, ButtonMenuItem } from '../ButtonMenu'

const StyledNav = styled.nav`
  margin-bottom: 40px;
`

const SlideButtonMenu = styled<React.FC<ButtonMenuProps & {activeButtonVars: Record<string, any>}>>(ButtonMenu)`
  position: relative;
  --width: ${({activeButtonVars}) => activeButtonVars.width}px;
  --left: ${({activeButtonVars}) => activeButtonVars.left}px;
  
  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    max-width: var(--width);
    left: var(--left);
    position: absolute;
    z-index: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: inherit;
    transition: max-width 0.2s ease-in-out, left 0.2s ease-in-out;
  }
`

const SlideButtonMenuItem = styled(ButtonMenuItem)`
  transition: color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
  background-color: transparent !important;
  z-index: 1;

  font-weight: ${({ isActive }) => isActive ? 'bold' : 'initial'}
`

const getActiveIndex = (pathname: string): number => {
  if (
    pathname.includes('/liquidity') ||
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/remove') ||
    pathname.includes('/find')
  ) {
    return 1
  }
  return 0
}

const Nav = () => {
  const location = useLocation()

  const ref = React.useRef<HTMLElement>()

  const [width, setWidth] = useState(87)
  const [left, setLeft] = useState(0)

  React.useEffect(() => {
    const elem = ref.current
    const to = setTimeout(() => {
      if (elem) {
        const menu: HTMLElement = elem.firstElementChild as HTMLElement
        const activeButton: HTMLElement = menu.children.item(getActiveIndex(location.pathname)) as HTMLElement
        // menu.style.setProperty('--width', `${activeButton.clientWidth.toString()}px`)
        setWidth(activeButton.clientWidth)
        // menu.style.setProperty('--left', `${activeButton.offsetLeft.toString()}px`)
        setLeft(activeButton.offsetLeft)
      }
    }, 100)
    return () => clearTimeout(to)

  })

  return (
    <StyledNav ref={ref}>
      <SlideButtonMenu activeButtonVars={{width, left}} activeIndex={getActiveIndex(location.pathname)} scale='sm' variant='subtle'>
        <SlideButtonMenuItem id='swap-nav-link' to='/swap' as={Link}>
          Swap
        </SlideButtonMenuItem>
        <SlideButtonMenuItem id='pool-nav-link' to='/liquidity' as={Link}>
          Liquidity
        </SlideButtonMenuItem>
      </SlideButtonMenu>
    </StyledNav>
  )
}

export default Nav
