import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { getNeomorphismFilterDropShadowValue } from 'theme'

const NeomophAnimate = keyframes`
    to { 
      filter: drop-shadow(0 0 0 transparent);
      transform: scale(0.98)
    }
`

const NeomorphLoaderWrapper = styled.div<{width?: string}>`
  width: 100%;
  height: 70%;
  display: flex;
  background-color: $color;
  justify-content: center;
  align-items: center;

  .logo {
    width: ${({width}) => width || "350px"};

    .top,
    .bottom {
      will-change: filter, transform;
      transform-origin: center center;
      fill: ${({ theme }) => theme.colors.background};
      filter: ${({ theme }) => getNeomorphismFilterDropShadowValue(theme.colors.background, 0.75, 20)};
    }
  }
`

const TopPath = styled.path`
  animation: ${NeomophAnimate} 1s infinite ease-in-out alternate;
`

const BottomPath = styled(TopPath)`
  animation: ${NeomophAnimate} 1s infinite ease-in-out alternate;
  animation-delay: -.5s;
`

export const NeomorphLoader = ({width}: {width?: string}) => {
  return (
    <>
      <NeomorphLoaderWrapper width={width}>
        <svg className="logo" viewBox="-10 -10 64 69" fill="none" xmlns="http://www.w3.org/2000/svg">
          <BottomPath
            className="bottom"
            d="M18.1793 16.111C18 16.914 18.057 17.7518 18.3434 18.5231C18.6298 19.2945 19.1333 19.9663 19.7932 20.4576L34.2719 31.0872C32.9231 34.2429 30.5143 36.828 27.4621 38.3954C24.4098 39.9628 20.906 40.4139 17.5565 39.6709C17.3839 39.6309 17.2135 39.5867 17.0452 39.5425C14.0614 38.7733 11.3978 37.0785 9.43653 34.7012C8.8424 33.9886 8.0281 33.4939 7.12195 33.2952C3.16614 32.4217 0.302379 37.0271 2.87576 40.1487C5.04379 42.7596 7.75731 44.8637 10.8255 46.313C13.8937 47.7623 17.242 48.5215 20.635 48.5374C24.028 48.5532 27.3833 47.8253 30.4649 46.4047C33.5464 44.9842 36.2795 42.9055 38.4718 40.3149C40.8929 37.4621 42.5829 34.0623 43.3955 30.4094L43.9994 27.711L24.8768 13.623C24.3072 13.1992 23.6406 12.9246 22.9378 12.8241C22.235 12.7236 21.5182 12.8005 20.8527 13.0477C20.1872 13.295 19.594 13.7047 19.1271 14.2397C18.6602 14.7747 18.3344 15.418 18.1793 16.111Z"
          />
          <TopPath
            className="top"
            d="M24.2399 28.3089L9.72121 17.4541C10.7086 15.1376 12.2742 13.1136 14.268 11.5762C16.2619 10.0388 18.617 9.03941 21.1078 8.6739C23.5985 8.30839 26.1414 8.58898 28.4926 9.48879C30.8439 10.3886 32.9246 11.8775 34.5355 13.8127C35.1538 14.5472 35.9982 15.0558 36.9364 15.2587C40.8648 16.1385 43.7076 11.552 41.1552 8.43891C38.3904 5.08823 34.7413 2.58069 30.6227 1.20133C26.5042 -0.178026 22.0814 -0.373864 17.8571 0.636078C13.6329 1.64602 9.77658 3.82123 6.72662 6.91444C3.67665 10.0076 1.55536 13.8948 0.603893 18.134L0 20.8345L19.1583 35.1477C19.7277 35.5715 20.3939 35.8463 21.0965 35.9469C21.7991 36.0476 22.5156 35.9709 23.181 35.7238C23.8464 35.4768 24.4394 35.0672 24.9062 34.5325C25.373 33.9977 25.6988 33.3546 25.8538 32.6618C26.0347 31.8578 25.9785 31.0186 25.692 30.246C25.4055 29.4733 24.9011 28.8004 24.2399 28.3089Z"
            fill="#296CDD"
          />
        </svg>
      </NeomorphLoaderWrapper>
    </>
  )
}
