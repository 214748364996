import React from 'react'
import styled, { css } from 'styled-components'

const StyledVideo = styled.section`

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 24px;

  ${({ theme }) => theme.mediaQueries.md} {
    //min-height: 100vh;
  }

  .card {

    width: 100%;
    padding: 14px;

    ${({ theme }) => theme.mediaQueries.md} {
      padding: 34px;
      width: auto;
      height: 50vh;
    }

    border-radius: 20px;
    ${({ theme }) => theme.isDark
            ? css`
              background: #212940;
              box-shadow: -10px -10px 30px #2F3C5E, 10px 10px 30px #0C0F1E;
            ` : css`
              background: #F0F0F3;
              box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
            `
    };


    video {
      height: 100%;
      width: 100%;
      border-radius: 20px;
    }
  }

`

export default function Video() {
  return <>
    <StyledVideo>
      {/* <div className='card'> */}
      {/*   <video src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4' controls /> */}
      {/* </div> */}
    </StyledVideo>
  </>
}
