import styled from 'styled-components'


export const StyledNavbar = styled.nav`
  padding: 24px;
  display: flex;
  align-items: center;
  font-size: 7px;

  .seperator {
    flex: 1;
  }

  ${({ theme }) => theme.mediaQueries.md} {

    font-size: 16px;
    padding: 35px;
  }
`