import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useLocation } from 'react-router-dom'
import { useWalletModal } from '@swapxco/uikit'
import { StyledDashboard } from './StyledDashboard'
import SidePanel from './SidePanel'
import Navbar from './Navbar'
import { navlinks } from './config'
import { NavLink } from './NavLink'
import Footer from './Footer'
import { socialLinks } from './consts'
import { BuyXwapButton } from './BuyXwapButton'
import { ChainSelect } from './ChainSelect'
import { ThemeToggle } from './ThemeToggle'
import useAuth from '../../hooks/useAuth'


export default function Dashboard({ children }) {

  const [collapsePanel, setCollapsePanel] = useState(false)
  const [openPanel, setOpenPanel] = useState(false)
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  const location = useLocation()

  useEffect(() => {
    setOpenPanel(false)
  }, [location])

  return <>
    <StyledDashboard className={`${collapsePanel && 'collapse-panel'} ${openPanel && 'open-panel'}`}>
      <div className='sidepanel'>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className='backdrop' onClick={() => setOpenPanel(false)} />
        <SidePanel onClose={() => setOpenPanel(false)}>
          {navlinks.map(props => <NavLink key={props.path || props.href} {...props} />)}
          {socialLinks}
        </SidePanel>
      </div>
      <main>
        <Navbar id='navbar' onPanelButtonClick={() => {
          setCollapsePanel(!collapsePanel)
          setOpenPanel(!openPanel)
        }}>
          {socialLinks}
          <div className="seperator" />
          &nbsp;&nbsp;
          <BuyXwapButton />
          &nbsp;&nbsp;
          <ChainSelect />
          &nbsp;&nbsp;&nbsp;
          {account ? (
            <button type='button' className='neomorph-button' onClick={() => onPresentAccountModal()}>
              {accountEllipsis}
            </button>
          ) : (
            <button type='button' className='neomorph-button primary' onClick={() => onPresentConnectModal()}>
              Connect
            </button>
          )}
          &nbsp;&nbsp;&nbsp;
          <ThemeToggle />
        </Navbar>
        <div className='content'>
          {children}
        </div>
      </main>
      <Footer />
    </StyledDashboard>
  </>
}