import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Button as UIKITButton, Link, LogoIcon, Text, useWalletModal } from '@swapxco/uikit'
import { useWeb3React } from '@web3-react/core'
import { newDarkBG, newLightBG} from './consts'
import useTheme from '../../hooks/useTheme'
import HeroArtWork from './Components/HeroArtWork'
import { Button } from '../../components/Button'
import useAuth from '../../hooks/useAuth'
import { cssValues } from '../../components/Dashboard/consts'

const StyledHeader = styled.header<{ openMenu: boolean }>`

  .hide-on-mobile {
    display: none;

    ${props => props.theme.mediaQueries.md} {
      display: initial;
    }
  }

  .hide-on-desktop {

    ${props => props.theme.mediaQueries.md} {
      display: none !important;
    }
  }

  .theme-toggle {
    border-radius: 40px;
    height: 25px;
    width: 25px;
    padding: 5px;

    ${({ theme }) => theme.isDark
            ? css`
              background: #383D58;
              box-shadow: -5px -5px 10px #333953, 10px 10px 30px #040714;
            ` : css`
              background: #F0F0F3;
              box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4)
            `
    };
  }


  .brand {
    padding: 0.25em 15px;
    font-size: 26px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 26px;
    }
  }


  .hero {
    display: flex;
    flex-direction: column;
    min-height: 70vh;

    ${props => props.theme.mediaQueries.md} {
      flex-direction: row;
    }

    .illustration-container {
      flex: 0.4;
      color: white;
      position: relative;
      z-index: 0;
      background-color: ${({ theme }) => theme.colors.primary};

      padding: 24px 30px;

      .container {
        max-width: 440px;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        .top {
          display: flex;
          justify-content: space-between;
        }


        .artwork {
          width: 100%;
          height: auto;
          transform: translateY(50px);

          ${props => props.theme.mediaQueries.md} {
            width: 125%;
            transform: translateX(10%);
          }
        }
      }

    }

    .hero-info {

      flex: 0.6;
      padding: 24px 30px;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: stretch;
      background: url("/images/landingpage/header-bg.svg") no-repeat bottom left;
      background-size: cover;

      ${props => props.theme.mediaQueries.md} {
        background-size: 100%;
      }

      .top {
        margin: 0 auto;
        max-width: 590px;

        .nav-links {
          display: flex;

          a {
            padding: 0.25em 15px;
            color: ${({ theme }) => theme.colors.text};
            flex-wrap: wrap;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            &::after {
              content: '';
              display: block;
              width: 100%;
              height: 3px;
              margin-top: -6px;
              background-color: ${({ theme }) => theme.colors.primary};
              flex: 1 0 100%;
              transition: transform 0.6s cubic-bezier(0.87, 0, 0.13, 1);
              transform: scaleX(0);
            }

            &:hover {
              text-decoration: none !important;

              &::after {
                transform: scaleX(1) !important;
              }
            }
          }
        }

        .nav-end {
          display: flex;

          & > * {
            margin: auto 5px;
          }
        }
      }

      .container {
        margin: auto;
        max-width: 590px;
        padding: 30px 0;
      }

      .title {
        font-size: 45px;
        line-height: 110%;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 80px;
          line-height: 75px;
        }

        span {
          font-weight: bold;
          color: ${({ theme }) => theme.colors.primary};
        }
      }

      .action-buttons {
        margin-top: 38px;
        display: flex;

        a, button {
          padding: 10px 20px;
          font-weight: 600;
          font-size: 14px;
          line-height: 30px;
          border-radius: 40px;
          transition: box-shadow 0.2s ${cssValues.easing}, filter 0.2s ${cssValues.easing};
          
          ${({ theme }) => theme.isDark
                  ? css`
                    background: linear-gradient(111.49deg, #192035 26.11%, #282E45 57.96%, #454A64 90.91%);
                    box-shadow: -10px -10px 30px #2F3C5E, 10px 10px 30px #0C0F1E;

                    &:hover {
                      filter: brightness(1.05);
                      box-shadow: -4px -4px 30px #2F3C5E, 4px 4px 30px #0C0F1E;
                    }

                    &:active {
                      box-shadow: -3px -3px 30px #2F3C5E, 3px 3px 30px #0C0F1E;
                    }

                  ` : css`
                    background: #F0F0F3;
                    box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);

                    &:hover {
                      filter: brightness(1.05);
                      box-shadow: -4px -4px 30px #FFFFFF, 4px 4px 30px rgba(174, 174, 192, 0.4);
                    }

                    &:active {
                      box-shadow: -3px -3px 30px #FFFFFF, 3px 3px 30px rgba(174, 174, 192, 0.4);
                    }
                  `
          };

          &.connect-wallet {
            background: #0064FF;
            ${({ theme }) => theme.isDark
                    ? css`
                      box-shadow: -10px -10px 30px #3A486A, 10px 10px 30px rgba(7, 99, 242, 0.54);
                    ` : css`
                      box-shadow: -5px -5px 20px #FFFFFF, 5px 5px 20px rgba(74, 143, 251, 0.64);
                    `
            };
          }


          ${({ theme }) => theme.mediaQueries.md} {
            padding: 15px 30px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .side-menu {
    position: fixed;
    padding: 27px 20px;
    top: 0;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background: linear-gradient(${({ theme }) => theme.isDark ? newDarkBG : newLightBG}b0, ${({ theme }) => theme.isDark ? newDarkBG : newLightBG}b0),
    url("/images/dashboard-sidepanel-bg.svg") no-repeat bottom right,
    linear-gradient(${({ theme }) => theme.isDark ? newDarkBG : newLightBG}, ${({ theme }) => theme.isDark ? newDarkBG : newLightBG});;
    background-size: 100% auto;
    transition: transform 0.2s ease-in-out;
    transform: translateX(${({ openMenu }) => openMenu ? 0 : -100}%);

    .brand {
      padding: 0.25em 15px;
      color: ${({ theme }) => theme.colors.primary};
    }

    & ~ .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      content: '';
      background-color: ${({ theme }) => theme.isDark ? newDarkBG : newLightBG};
      filter: opacity(${({ openMenu }) => openMenu ? 0.7 : 0});
      z-index: 9;
      cursor: pointer;
      pointer-events: ${({ openMenu }) => openMenu ? '' : 'none'};
      transition: filter 0.2s ease-in-out;
    }

    & > * {
      margin-bottom: 20px;
    }

    .nav-links {
      flex-direction: column;

      a {
        padding: 0.25em 15px;
        color: ${({ theme }) => theme.colors.text};
        margin-bottom: 20px;
      }

    }

    .nav-end {
      display: flex;
      margin: initial -5px;

      & > * {
        margin: auto 5px;
      }
    }
  }

`

export default function Header() {
  const { isDark, toggleTheme, theme } = useTheme()
  const [openMenu, setOpenMenu] = useState(false)
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : null

  const drawerable = <>
    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
    <div className='nav-links' onClick={e => setOpenMenu(false)} onKeyPress={() => setOpenMenu(false)}>
      <Link href='https://docs.swapx.co/swapx/' target='_blank'>Documentation</Link>
      <Link href='https://info.swapx.co/' target='_blank'>Info</Link>
      <Link href='#community-links'>Community</Link>
      <Link href='https://docs.swapx.co/swapx/introduction/master'
            target='_blank'>About</Link>
      <Link href='./contact'>Contact</Link>
      <Button className='theme-toggle' onClick={() => toggleTheme()}>
        {!isDark ? <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='7.5' cy='7.5' r='7.5' fill='#FFB672' />
        </svg> : <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fillRule='evenodd' clipRule='evenodd'
                d='M13.9263 6.63397C12.7688 7.48163 11.341 7.98213 9.79636 7.98213C5.93173 7.98213 2.79883 4.84922 2.79883 0.984593C2.79883 0.650412 2.82225 0.321702 2.86755 1.54573e-05C1.129 1.27315 0 3.32942 0 5.64939C0 9.51402 3.1329 12.6469 6.99753 12.6469C10.528 12.6469 13.4478 10.0324 13.9263 6.63397Z'
                fill='#FFB672' />
        </svg>}
      </Button>
    </div>
  </>


  return <>
    <StyledHeader openMenu={openMenu}>
      <div className='hero'>
        <div className='illustration-container'>
          <div className='container'>
            <div className='top'>
              <div className='brand'>
                <LogoIcon height='46px' color='currentColor' />
                SwapX
              </div>
              <UIKITButton style={{ padding: 0 }} variant='text' onClick={() => setOpenMenu(true)}
                           className='hide-on-desktop'>
                <svg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M1 1L20 0.999998' stroke='white' strokeWidth='2' strokeLinecap='round' />
                  <path d='M1 8L10.5 8M20 8L15.25 8' stroke='white' strokeWidth='2' strokeLinecap='round' />
                  <path d='M1 15L20 15' stroke='white' strokeWidth='2' strokeLinecap='round' />
                </svg>
              </UIKITButton>
            </div>
            <HeroArtWork className='artwork' />
          </div>
        </div>
        <div className='hero-info'>
          <div className='top hide-on-mobile'>{drawerable}</div>
          <div className='container'>
            <Text as='h1' my='20px' fontSize='80px' lineHeight='75px' className='title'>
              All-in-one <span>Launchpad</span>
            </Text>
            <Text as='p'>
              SwapX is a launchpad platform which allows users to create and deploy tokens, NFTs, and presale contracts
              for the lowest competitive fees.
            </Text>
            <div className='action-buttons'>
              <Button as={Link} href='./presales'>Launch DApp</Button>
              &nbsp;
              &nbsp;
              {account ? (
                <Button variant='tertiary' onClick={() => onPresentAccountModal()}>
                  {accountEllipsis}
                </Button>
              ) : (
                <Button className='connect-wallet' style={{ backgroundColor: theme.colors.primary, color: 'white' }}
                        onClick={() => onPresentConnectModal()}>
                  Connect Wallet
                </Button>
              )}
              {/* <UIKITButton variant='text' as={Link} href='./#'>Quick Video</UIKITButton> */}
            </div>
          </div>
        </div>
      </div>
      <div className='side-menu hide-on-desktop'>
        <UIKITButton style={{ padding: '0 5px', marginLeft: 'auto' }} variant='text' onClick={() => setOpenMenu(false)}>
          <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L2.75 2.75M15 15L8 8L6.25 6.25L4.5 4.5' stroke='#3D5AF1' strokeWidth='2'
                  strokeLinecap='round' />
            <path d='M15 1L1 15' stroke='#3D5AF1' strokeWidth='2' strokeLinecap='round' />
          </svg>
        </UIKITButton>
        <div className='brand'>
          <LogoIcon height='46px' color='currentColor' />
          SwapX
        </div>
        {drawerable}
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setOpenMenu(false)} onKeyPress={() => setOpenMenu(false)}
           className='backdrop  hide-on-desktop' />
    </StyledHeader>
  </>
}
