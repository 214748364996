// Set of helper functions to facilitate wallet setup

import { BASE_BSC_SCAN_URLS, BASE_URL, CHAIN_NAMES } from 'config'
import { CHAIN_ID_KEY } from 'config/constants'
import { nodes_by_chain } from './getRpcUrl'


/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = window.ethereum;

  if (provider) {
    // @MOUD getting the chain ID from localstorage or from env, used to just be env
    const chainIdString: string = localStorage.getItem(CHAIN_ID_KEY) ?? process.env.REACT_APP_CHAIN_ID;
    const chainId: number = parseInt(chainIdString, 10);
    const nodes: string[] = nodes_by_chain[chainId];
    console.log("setupNetwork chainId: ", chainId);
    console.log("setupNetwork nodes: ", nodes);

    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName: CHAIN_NAMES[chainId],
            nativeCurrency: {
              name: 'BNB',
              symbol: 'bnb',
              decimals: 18,
            },
            // @MOUD the chainId changes the rpc url
            rpcUrls: nodes,
            blockExplorerUrls: [`${BASE_BSC_SCAN_URLS[chainId]}/`],
          },
        ],
      })
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number, tokenImage?: string) => {
  const iconName: string = (tokenSymbol === 'SWAPX-LP') ? 'swapx' : tokenAddress;
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage || `${BASE_URL}/images/tokens/${iconName}.png`,
      },
    },
  })

  return tokenAdded
}
