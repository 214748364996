import { createAction } from '@reduxjs/toolkit'
import { ChainIdState } from "./interfaces";

// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
export const updateVersion = createAction<void>('global/updateVersion')

// @MOUD action to update the active chain ID
export const updateChainId = createAction<ChainIdState>('global/updateChainId')

export default updateVersion
