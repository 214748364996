import React, { useState } from 'react'
import SwiperCore, { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import styled, { css } from 'styled-components'
import { Button } from 'components/Button'
import Container from '../../components/Layout/Container'
import { newDarkBG, newLightBG } from './consts'

SwiperCore.use([Mousewheel])

const StyledArticles = styled.section`

  .container {


    position: relative;

    padding: 24px 0;

    .controls {
      display: flex;
      justify-content: end;

      button {
        ${({ theme }) => theme.isDark
                ? css`
                  background: #192035;
                  box-shadow: 0px 6px 10px #313C57, inset 0px 4px 10px rgba(11, 17, 34, 0.6);
                ` : css`
                  box-shadow: 0px 6px 10px #FFFFFF, inset 0px 4px 10px rgba(174, 174, 192, 0.36);
                  background: #F0F0F3;
                `
        };

        margin: 10px 5px;
      }

    }
  }

  .swiper-container {
    padding: 35px 24px;
    //
    //   &:after, &:before {
    //     //width: 50px;
      //     ${({ theme }) => theme.mediaQueries.md} {
    //       width: 180px;
    //     }
    //   }
    //
    //   &:after {
    //     content: '';
    //     position: absolute;
    //     display: block;
    //     height: 100%;
    //     pointer-events: none;
    //     z-index: 5;
      //     background: linear-gradient(90deg, transparent, ${({ theme }) => theme.isDark ? newDarkBG : newLightBG});
    //     top: 0;
    //     right: 0;
    //   }
    //
    //   &:before {
    //     content: '';
    //     position: absolute;
    //     display: block;
    //     height: 100%;
    //     pointer-events: none;
    //     z-index: 5;
    //     transition: filter 0.5s ease-in-out;
      //     background: linear-gradient(270deg, transparent, ${({ theme }) => theme.isDark ? newDarkBG : newLightBG});
    //     top: 0;
    //     left: 0;
    //     filter: opacity(0);
    //   }
    //
    // }
    //
    // &.swiper-index-moved {
    //   .swiper-container::before {
    //     filter: opacity(1);
    //   }
    // }

    .card {
      //max-width: 357px;
      padding: 13px;
      display: block;
      //min-height: 168px;

      ${({ theme }) => theme.isDark
              ? css`
                background: #212940;
                box-shadow: -10px -10px 30px #2F3C5E, 10px 10px 30px #0C0F1E;
              ` : css`
                background: #F0F0F3;
                box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.4);
              `
      };

      border-radius: 10px;

      img {
        aspect-ratio: 17 / 8;
        object-fit: cover;
        object-position: top center;
        border-radius: 10px;
      }
    }
  }
`

export default function Articles(props) {

  const [swiper, setSwiper] = useState<SwiperCore>()
  const [index, setIndex] = useState(0)

  return <>
    <StyledArticles>
      <Container className={`container ${index > 0 && 'swiper-index-moved'}`} onFocusCapture={e => {
        const target = e.target.closest('.swiper-slide')
        if (target && swiper) {
          swiper.slideTo(Array.from(target.parentElement.childNodes).indexOf(target))
        }
      }}>

        <div className='controls'>
          <Button variant='text' onClick={() => {
            swiper.slideTo(swiper.activeIndex - 1 >= 0 ? swiper.activeIndex - 1 : swiper.slides.length - 2)
          }}>
            <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M15.1087 8.98828C15.661 8.98828 16.1087 8.54057 16.1087 7.98828C16.1087 7.436 15.661 6.98828 15.1087 6.98828V8.98828ZM0.42418 7.28117C0.0336555 7.6717 0.0336555 8.30486 0.42418 8.69539L6.78814 15.0593C7.17867 15.4499 7.81183 15.4499 8.20235 15.0593C8.59288 14.6688 8.59288 14.0357 8.20235 13.6451L2.5455 7.98828L8.20235 2.33143C8.59288 1.9409 8.59288 1.30774 8.20235 0.917213C7.81183 0.526689 7.17867 0.526689 6.78814 0.917213L0.42418 7.28117ZM15.1087 6.98828H1.13129V8.98828H15.1087V6.98828Z'
                fill='#3D5AF1' />
            </svg>
          </Button>
          <Button variant='text' onClick={() => {
            swiper.slideTo((swiper.activeIndex + 1) % (swiper.slides.length - 2))
          }}>
            <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.08524 8.98828C0.532955 8.98828 0.0852404 8.54057 0.0852404 7.98828C0.0852404 7.436 0.532955 6.98828 1.08524 6.98828V8.98828ZM15.7698 7.28117C16.1603 7.6717 16.1603 8.30486 15.7698 8.69539L9.40583 15.0593C9.0153 15.4499 8.38214 15.4499 7.99162 15.0593C7.60109 14.6688 7.60109 14.0357 7.99162 13.6451L13.6485 7.98828L7.99162 2.33143C7.60109 1.9409 7.60109 1.30774 7.99162 0.917213C8.38214 0.526689 9.0153 0.526689 9.40583 0.917213L15.7698 7.28117ZM1.08524 6.98828H15.0627V8.98828H1.08524V6.98828Z'
                fill='#3D5AF1' />
            </svg>
          </Button>
        </div>
        <Swiper
          onSwiper={s => setSwiper(s)}
          mousewheel={{ forceToAxis: true }}
          slidesPerView={1.5}
          spaceBetween={15}
          loop
          onActiveIndexChange={s => setIndex(s.activeIndex)}
          breakpoints={{
            852: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
        >


          <SwiperSlide>
            <a href='https://twitter.com/SwapXco/status/1507288672550408193' className='card' target='_blank'
               rel='noreferrer'>
              <img src='/images/landingpage/articles/ezgif-4-6922b09cb4.webp' alt='' />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href='https://coinmarketcap.com/currencies/swapx/' target='_blank' rel='noreferrer' className='card'>
              <img src='/images/landingpage/articles/CoinMarketCap.png' alt='CoinMarketCap' />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href='https://swapx.co/presales/0x1a639154E6d8187fCb4fD607882FDC641ecB1836' className='card' target='_blank'
               rel='noreferrer'>
              <img src='/images/landingpage/articles/bnbxwap.png' alt='' />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href='https://www.coingecko.com/en/coins/swapx' className='card' target='_blank' rel='noreferrer'>
              <img src='/images/landingpage/articles/coingecko.png' alt='CoinGecko' />
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a href='https://twitter.com/InterFiNetwork/status/1473585062511742979' className='card' target='_blank'
               rel='noreferrer'>
              <img src='/images/landingpage/articles/InterFi.png' alt='InterFi Audits & KYC' />
            </a>
          </SwiperSlide>
        </Swiper>
      </Container>
    </StyledArticles>
  </>
}
