import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import SwiperCore, { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { light, Text, useMatchBreakpoints } from '@swapxco/uikit'
import Container from '../../components/Layout/Container'
import { Button } from '../../components/Button'

SwiperCore.use([Mousewheel])

const floatingBitcoinKeyframes = keyframes`
  0%, 100% {
    transform: translate(25%, -25%) rotateY(0deg);
  }
  50% {
    transform: translate(25%, -30%) rotateY(30deg);
  }
`


const floatingSwapCoinKeyframes = keyframes`
  0%, 100% {
    transform: translate(-40%, 40%) rotateY(0deg);
  }
  50% {
    transform: translate(-40%, 45%) rotateY(20deg);
  }
`

const StyledNFT = styled.section<{ breakPoint: boolean }>`

  padding: 100px 0;

  .title {
    font-weight: normal;
    text-align: center;
    font-size: 30px;
    line-height: 45px;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 60px;
      line-height: 80px;
    }

    span {
      font-weight: bold;
    }
  }

  .container {
    //display: flex;
    //justify-content: center;
    //flex-wrap: wrap;
    padding: 24px 0;
  }

  .controls {
    display: flex;
    margin: 10px 0;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.md} {
      justify-content: end;
    }

    button {
      ${({ theme }) => theme.isDark
              ? css`
                background: #192035;
                box-shadow: 0px 6px 10px #313C57, inset 0px 4px 10px rgba(11, 17, 34, 0.6);
              ` : css`
                box-shadow: 0px 6px 10px #FFFFFF, inset 0px 4px 10px rgba(174, 174, 192, 0.36);
                background: #F0F0F3;
              `
      };

      margin: 10px 5px;
    }

  }

  .nft-info {
    z-index: 0;
    position: relative;
    margin: auto 0;
    color: ${light.colors.text} !important;

    .swiper-container {
      padding: 50px 0;
      min-height: calc(490px + 20vh);
      //margin: -30px;
    }

    .swiper-slide {
      border-radius: 20px;
      ${({ theme }) => theme.isDark ? css`
        background: #212940;
        filter: drop-shadow(-10px -10px 10px #2F3C5E) drop-shadow(10px 10px 10px #0C0F1E);
      ` : css`
        background: #F0F0F3;
        filter: drop-shadow(-10px -10px 10px #FFFFFF) drop-shadow(10px 10px 10px rgba(174, 174, 192, 0.4));
      `}

      transform-origin: top center;
      transition: transform 0.4s ease-in-out, margin 0.4s ease-in-out;
      transform: scale(0.9) translateY(25%);

      img {
        position: relative;
        z-index: 1;
      }

      .content {
        position: relative;
        padding: 0 30px;
        z-index: 0;
        max-height: 0;
        transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
        overflow: hidden;
      }

      &.swiper-slide-active {
        transform: scale(1);
        margin-bottom: 0;

        .content {
          max-height: 500px;
          padding: 30px;

        }
      }
    }
  }
`

export default (function NFT(props) {
  const { isSm, isMd } = useMatchBreakpoints()
  const [swiper, setSwiper] = useState<SwiperCore>()
  const [index, setIndex] = useState(0)

  return <>
    <StyledNFT breakPoint={isSm || isMd}>
      <Container className='container'>
        <Text className='title'>Superpowered <br /> <span className='text-primary'>NFTs</span> need friends.</Text>
        <div className='nft-info'>
          <Swiper
            onSwiper={s => setSwiper(s)}
            slidesPerView={1.5}
            spaceBetween={8}
            centeredSlides
            loop
            onActiveIndexChange={s => setIndex(s.activeIndex)}
            breakpoints={{
              852: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            <SwiperSlide>
              <img src='/images/landingpage/nfts/2.svg' alt='' width='100%' />
              <Text className='content'>
                <span className='text-primary' style={{ fontWeight: 'bold' }}>9,999</span> mascots have landed in the
                Metaverse with
                one purpose and no
                short sighted vision.
                <br />
                <br />
                Our NFT collection is <span className='text-primary'>exclusively</span> generated by entrants into the
                SwapX
                Token Presale.
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <img src='/images/landingpage/nfts/1.svg' alt='' width='100%' />
              <Text className='content'>
                <span className='text-primary' style={{ fontWeight: 'bold' }}>9,999</span> mascots have landed in the
                Metaverse with
                one purpose and no
                short sighted vision.
                <br />
                Our NFT collection is <span className='text-primary'>exclusively</span> generated by entrants into the
                SwapX
                Token Presale.
              </Text>
            </SwiperSlide>
            <SwiperSlide>
              <img src='/images/landingpage/nfts/3.svg' alt='' width='100%' />
              <Text className='content'>
                <span className='text-primary' style={{ fontWeight: 'bold' }}>9,999</span> mascots have landed in the
                Metaverse with
                one purpose and no
                short sighted vision.
                <br />
                Our NFT collection is <span className='text-primary'>exclusively</span> generated by entrants into the
                SwapX
                Token Presale.
              </Text>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='controls'>
          <Button variant='text' onClick={() => {
            swiper.slideTo(swiper.activeIndex - 1 >= 0 ? swiper.activeIndex - 1 : swiper.slides.length - 1)
          }}>
            <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M15.1087 8.98828C15.661 8.98828 16.1087 8.54057 16.1087 7.98828C16.1087 7.436 15.661 6.98828 15.1087 6.98828V8.98828ZM0.42418 7.28117C0.0336555 7.6717 0.0336555 8.30486 0.42418 8.69539L6.78814 15.0593C7.17867 15.4499 7.81183 15.4499 8.20235 15.0593C8.59288 14.6688 8.59288 14.0357 8.20235 13.6451L2.5455 7.98828L8.20235 2.33143C8.59288 1.9409 8.59288 1.30774 8.20235 0.917213C7.81183 0.526689 7.17867 0.526689 6.78814 0.917213L0.42418 7.28117ZM15.1087 6.98828H1.13129V8.98828H15.1087V6.98828Z'
                fill='#3D5AF1' />
            </svg>
          </Button>
          <Button variant='text' onClick={() => {
            swiper.slideTo((swiper.activeIndex + 1) % (swiper.slides.length))
          }}>
            <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.08524 8.98828C0.532955 8.98828 0.0852404 8.54057 0.0852404 7.98828C0.0852404 7.436 0.532955 6.98828 1.08524 6.98828V8.98828ZM15.7698 7.28117C16.1603 7.6717 16.1603 8.30486 15.7698 8.69539L9.40583 15.0593C9.0153 15.4499 8.38214 15.4499 7.99162 15.0593C7.60109 14.6688 7.60109 14.0357 7.99162 13.6451L13.6485 7.98828L7.99162 2.33143C7.60109 1.9409 7.60109 1.30774 7.99162 0.917213C8.38214 0.526689 9.0153 0.526689 9.40583 0.917213L15.7698 7.28117ZM1.08524 6.98828H15.0627V8.98828H1.08524V6.98828Z'
                fill='#3D5AF1' />
            </svg>
          </Button>
        </div>
      </Container>
    </StyledNFT>
  </>
}) as React.FC