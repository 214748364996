import sample from 'lodash/sample'

// Array of available nodes to connect to
export const base_nodes = [process.env.REACT_APP_NODE_1,process.env.REACT_APP_NODE_2,  process.env.REACT_APP_NODE_3]

// @MOUD this is where we define the RPC URLS for each blockchain, the base nodes are used for BSC Mainnet, alongside SPEEDYNODE nodes as backup.
export const nodes_by_chain = {
    56: [process.env.REACT_APP_QUICKNODE_MAINNET, ...base_nodes],
    97: [process.env.REACT_APP_QUICKNODE_TESTNET]
}

// @MOUD return a specific RPC url based on a specific chain
export const getNodeUrlByChain = (chain: number) => {
  return sample(nodes_by_chain[chain])
}
