import { Interface, FunctionFragment } from '@ethersproject/abi'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useBlockNumber } from '../application/hooks'
import { AppDispatch, AppState } from '../index'
import {
  addMulticallListeners,
  Call,
  removeMulticallListeners,
  parseCallKey,
  toCallKey,
  ListenerOptions,
} from './actions'

export interface Result extends ReadonlyArray<any> {
  readonly [key: string]: any
}

type MethodArg = string | number | BigNumber
type MethodArgs = Array<MethodArg | MethodArg[]>

type OptionalMethodInputs = Array<MethodArg | MethodArg[] | undefined> | undefined

function isMethodArg(x: unknown): x is MethodArg {
  return ['string', 'number'].indexOf(typeof x) !== -1
}

function isValidMethodArgs(x: unknown): x is MethodArgs | undefined {
  return (
    x === undefined ||
    (Array.isArray(x) && x.every((xi) => isMethodArg(xi) || (Array.isArray(xi) && xi.every(isMethodArg))))
  )
}

interface CallResult {
  readonly valid: boolean
  readonly data: string | undefined
  readonly blockNumber: number | undefined
}

const INVALID_RESULT: CallResult = { valid: false, blockNumber: undefined, data: undefined }

// use this options object
export const NEVER_RELOAD: ListenerOptions = {
  blocksPerFetch: Infinity,
}

/** @MOUD
 * the lowest level call for subscribing to contract data, this is not making calls to
 * any contracts! This function deals directly with redux, confirming and storing calls that
 * are updated constantly.
 * 
 * @param calls the calls want to make
 * @param options listener options (ex: blocksPerFetch which is how often data should fetch, default 1)
 * @returns results of the calls in the form of CallResult array
 */
function useCallsData(calls: (Call | undefined)[], options?: ListenerOptions): CallResult[] {
  const { chainId } = useActiveWeb3React()
  // @MOUD get calls and their results that have been stored in state. {chainId: [{'address-calldata': resultObject}]}
  const callResults = useSelector<AppState, AppState['multicall']['callResults']>(
    (state) => state.multicall.callResults,
  )
  
  const dispatch = useDispatch<AppDispatch>()
  // @MOUD the to call key converts the call to a string: 'address-calldata'
  const serializedCallKeys: string = useMemo(
    () =>
      JSON.stringify(
        calls
          ?.filter((c): c is Call => Boolean(c))
          ?.map(toCallKey)
          ?.sort() ?? [],
      ),
    [calls],
  )

  // update listeners when there is an actual change that persists for at least 100ms
  useEffect(() => {
    const callKeys: string[] = JSON.parse(serializedCallKeys)
    if (!chainId || callKeys.length === 0) return undefined
    
    // @MOUD parseCallKey converts a call key 'address-calldata' back into an object.
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const calls = callKeys.map((key) => parseCallKey(key))

    // @MOUD store the calls in state by their chainId alongside their options 
    dispatch(addMulticallListeners({ chainId, calls, options }))

    return () => {
      dispatch(removeMulticallListeners({ chainId, calls, options }))
    }
  }, [chainId, dispatch, options, serializedCallKeys])

  return useMemo(
    () =>
      calls.map<CallResult>((call) => {
        if (!chainId || !call) return INVALID_RESULT
        // @MOUD get the result of the call from redux using the chainId and the call key
        const result = callResults[chainId]?.[toCallKey(call)]
        let data
        if (result?.data && result?.data !== '0x') {
          // eslint-disable-next-line prefer-destructuring
          data = result.data
        }

        return { valid: true, data, blockNumber: result?.blockNumber }
      }),
    [callResults, calls, chainId],
  )
}

export interface CallState<T = Result> {
  readonly valid: boolean
  // the result, or undefined if loading or errored/no data
  readonly result: (T) | undefined
  // true if the result has never been fetched
  readonly loading: boolean
  // true if the result is not for the latest block
  readonly syncing: boolean
  // true if the call was made and is synced, but the return data is invalid
  readonly error: boolean
}

const INVALID_CALL_STATE: CallState = { valid: false, result: undefined, loading: false, syncing: false, error: false }
const LOADING_CALL_STATE: CallState = { valid: true, result: undefined, loading: true, syncing: true, error: false }

/** @MOUD
 * factory to take call results and convert them to CallState object
 * @param callResult 
 * @param contractInterface 
 * @param fragment 
 * @param latestBlockNumber 
 * @returns 
 */
function toCallState(
  callResult: CallResult | undefined,
  contractInterface: Interface | undefined,
  fragment: FunctionFragment | undefined,
  latestBlockNumber: number | undefined,
): CallState {
  if (!callResult) return INVALID_CALL_STATE
  // console.log("callResult: ", callResult);
  const { valid, data, blockNumber } = callResult
  if (!valid) return INVALID_CALL_STATE
  if (valid && !blockNumber) return LOADING_CALL_STATE
  if (!contractInterface || !fragment || !latestBlockNumber) return LOADING_CALL_STATE
  const success = data && data.length > 2
  const syncing = (blockNumber ?? 0) < latestBlockNumber
  let result: Result | undefined
  if (success && data) {
    try {
      result = contractInterface.decodeFunctionResult(fragment, data)
    } catch (error) {
      console.debug('Result data parsing failed', fragment, data)
      return {
        valid: true,
        loading: false,
        error: true,
        syncing,
        result,
      }
    }
  }
  return {
    valid: true,
    loading: false,
    syncing,
    result,
    error: !success,
  }
}

export function useSingleContractMultipleData(
  contract: Contract | null | undefined,
  methodName: string,
  callInputs: OptionalMethodInputs[],
  options?: ListenerOptions,
): CallState[] {
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName), [contract, methodName])

  const calls = useMemo(
    () =>
      contract && fragment && callInputs && callInputs.length > 0
        ? callInputs.map<Call>((inputs) => {
            return {
              address: contract.address,
              callData: contract.interface.encodeFunctionData(fragment, inputs),
            }
          })
        : [],
    [callInputs, contract, fragment],
  )

  const results = useCallsData(calls, options)

  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return results.map((result) => toCallState(result, contract?.interface, fragment, latestBlockNumber))
  }, [fragment, contract, results, latestBlockNumber])
}

/**
 * @MOUD welcome to the world of multicall, we're calling multiple contracts to get a single
 * piece of information, (ex: calling serveral LPs to get their balances)
 * @param addresses the address of the contracts to request data from
 * @param contractInterface the contract interface shared by the contracts
 * @param methodName the name of the contract method we're going to call
 * @param callInputs optional arguments for the method we're calling
 * @param options additional options to pass to callData()
 * @returns 
 */
export function useMultipleContractSingleData(
  addresses: (string | undefined)[],
  contractInterface: Interface,
  methodName: string,
  callInputs?: OptionalMethodInputs,
  options?: ListenerOptions,
): CallState[] {
  // console.log("@Muticall addresses: ", addresses);
  // @MOUD get the function from the contract interface using the method name
  const fragment = useMemo(() => contractInterface.getFunction(methodName), [contractInterface, methodName])
  // console.log("@Muticall fragment: ", fragment);

  // @MOUD Generate the callData, an encoded string containing the function and the inputs if any
  const callData: string | undefined = useMemo(
    () =>
      fragment && isValidMethodArgs(callInputs)
        ? contractInterface.encodeFunctionData(fragment, callInputs) : undefined,
    [callInputs, contractInterface, fragment],
  )
  // console.log("@Muticall callData: ", callData);

  // @MOUD Generate the calls by looping over each of the addresses and building the Call objects 
  const calls = useMemo(
    () =>
      fragment && addresses && addresses.length > 0 && callData
        ? addresses.map<Call | undefined>((address) => {
            return address && callData ? { address, callData } : undefined
          })
        : [],
    [addresses, callData, fragment],
  )

  // console.log("@Muticall calls: ", calls);

  // @MOUD Make the calls and get their results
  const results = useCallsData(calls, options)

  const latestBlockNumber = useBlockNumber()

  return useMemo(() => {
    return results.map((result) => toCallState(result, contractInterface, fragment, latestBlockNumber))
  }, [fragment, results, contractInterface, latestBlockNumber])
}

/** 
 * @MOUD Get the result of a single call to a specific method in a single contract, used in
 * useCurrentBlockTimestamp()
 * @returns The result retrieved from state
 */
export function useSingleCallResult<T extends Contract, K extends keyof T>(
  contract: T | null | undefined,
  methodName: K,
  inputs?: OptionalMethodInputs,
  options?: ListenerOptions,
): CallState {

  // @ts-ignore
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName as string), [contract, methodName])

  const calls = useMemo<Call[]>(() => {
    return contract && fragment && isValidMethodArgs(inputs)
      ? [
          {
            address: contract.address,
            callData: contract.interface.encodeFunctionData(fragment, inputs),
          },
        ]
      : []
  }, [contract, fragment, inputs])

  const result = useCallsData(calls, options)[0]
  const latestBlockNumber = useBlockNumber()
  // @ts-ignore
  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, latestBlockNumber)
  }, [result, contract, fragment, latestBlockNumber])
}

// @ts-ignore
export function useSingleCallResultTyped<T extends Contract, K extends keyof T>(
  contract: T | null | undefined,
  methodName: K,
  inputs?: OptionalMethodInputs,
  options?: ListenerOptions,// @ts-ignore
): CallState<Awaited<ReturnType<T[K & string]>>[]> {

  // @ts-ignore
  const fragment = useMemo(() => contract?.interface?.getFunction(methodName as string), [contract, methodName])

  const calls = useMemo<Call[]>(() => {
    return contract && fragment && isValidMethodArgs(inputs)
      ? [
        {
          address: contract.address,
          callData: contract.interface.encodeFunctionData(fragment, inputs),
        },
      ]
      : []
  }, [contract, fragment, inputs])

  const result = useCallsData(calls, options)[0]
  const latestBlockNumber = useBlockNumber()

  // @ts-ignore
  return useMemo(() => {
    return toCallState(result, contract?.interface, fragment, latestBlockNumber)
  }, [result, contract, fragment, latestBlockNumber])
}
